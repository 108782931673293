const styles = theme => ({
  ...theme.classes,
  cssLabel: {
    backgroundColor: '#fff',
    color: '#96A3AF',
    marginTop: -10,
    fontSize: 14,
    height: '50%',
    minWidth: 40,
    '&$cssFocused': {
      color: '#96A3AF',
      marginTop: 0,
    },
  },
  cssDateLabel: {
    minWidth: 82,
    left: -2,
  },
  cssTxtAreaLabel: {
    height: 'auto',
  },
  cssSelectLabel: {
    color: '#96A3AF',
    marginTop: 0,
    fontSize: 14,
    '&$cssFocused': {
      color: '#96A3AF',
      marginTop: 0,
    },
  },
  cssShrink: {
    backgroundColor: 'transparent',
    color: '#96A3AF',
    marginTop: 0,
  },
  cssShrink2: {
    color: '#96A3AF',
    marginTop: 0,
  },
  cssDateShrink: {
    minWidth: 'auto',
  },
  cssFocused: {},
  cssOutlinedInput: {
    backgroundColor: '#fff',
    '& $notchedOutline': {
      borderColor: '#D9D7D7',
      borderWidth: 1,
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cssOutlinedInputRequired: {
    backgroundColor: '#fff',
    '& $notchedOutline': {
      borderColor: theme.palette.text.primary,
      borderWidth: 1,
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cssOutlinedInputDisabled: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#E8EAEE',
      borderWidth: 1,
    },
    '& fieldset': {
      borderColor: '#E8EAEE !important',
    },
  },
  txtareawrapper: {
    position: 'relative',
    '& p': {
      top: 'auto',
      bottom: 4,
    },
    '&[data-disabled="true"] div': {
      backgroundColor: '#F6F7F7',
    },
    '& textarea': {
      fontWeight: 400,
    },
    '&[data-disabled="true"] textarea': {
      color: '#949DB2',
    },
    '&[data-disabled="true"] label': {
      backgroundColor: 'transparent',
      color: '#96A3AF',
    },
    '&[data-disabled="true"] fieldset': {
      color: '#D9D7D7',
    },
  },
  txtArea: {
    position: 'relative',
    width: '100%',
    margin: '0 0 20px 0',
  },
  txtArea82: {
    position: 'relative',
    width: '100%',
    margin: '0 0 20px 0',
    '& textarea': {
      height: 82,
    },
  },
  cssTextArea: {
    backgroundColor: '#fff',
    padding: '10px 0',
    '& $notchedOutline': {
      borderColor: '#D9D7D7',
      borderWidth: 1,
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cssTextAreaRequired: {
    backgroundColor: '#fff',
    padding: '10px 0',
    '& $notchedOutline': {
      borderColor: theme.palette.text.primary,
      borderWidth: 1,
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  rootDate: {
    width: '100%',
    marginBottom: 20,
    '& p': {
      margin: '4px 0 0',
    },
  },
  rootTime: {
    width: '100%',
    marginBottom: 20,
    '& p': {
      margin: '4px 0 0',
    },
  },
  cssDate: {
    backgroundColor: '#fff',
    padding: 0,
    '& $notchedOutline': {
      borderColor: '#D9D7D7',
      borderWidth: '1 !important',
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2 !important',
    },
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
  cssDateRequired: {
    backgroundColor: '#fff',
    padding: 0,
    '& $notchedOutline': {
      borderColor: theme.palette.text.primary,
      borderWidth: '1 !important',
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: '2 !important',
    },
    '& fieldset': {
      borderWidth: '1 !important',
    },
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
  cssSelect: {
    overflow: 'hidden',
    lineHeight: '34px',
  },
  cssSelectDisabled: {
    '& div': {
      color: '#ACB3B5',
    },
    '& > div': {
      backgroundColor: '#F6F7F7',
    },
    '& svg': {
      fill: '#ACB3B5 !important',
    },
    '& select': {
      color: '#ACB3B5',
    },
  },
  cssInputDisabled: {
    backgroundColor: '#F6F7F7',
    '& fieldset': {
      borderColor: '#ACB3B5 !important',
    },
    '& input': {
      color: '#ACB3B5',
    },
  },
  notchedOutline: {},
  margin: {
    margin: theme.spacing.unit,
  },
  inputHolder: {
    position: 'relative',
  },
  autocompleteHolder: {
    position: 'relative',
    width: '100%',
    '& .bg': {
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'none',
      zIndex: 1,
    },
    '&[data-disabled="true"] .bg': {
      display: 'block',
    },
    '&[data-disabled="true"] div div div div': {
      backgroundColor: '#F6F7F7',
    },
    '&[data-disabled="true"] svg rect': {
      fill: '#D9D7D7',
    },
    '&.required fieldset': {
      borderColor: '#5D5B5B',
    },
    '&[data-disabled="true"] fieldset': {
      borderColor: '#D9D7D7',
    },
    '&[data-disabled="true"] label': {
      color: '#BDC1C9 !important',
    },
    '&[data-disabled="true"] p': {
      color: '#BDC1C9',
    },
    '&[data-disabled="true"] p.errorlbl': {
      color: '#f44336',
    },
  },
  inputWrapper: {
    width: '100%',
    margin: '0 0 30px',
  },
  inputWrapperMb20: {
    width: '100%',
    margin: '0 0 20px',
  },
  inptRequired: {
    '& > label': {
      color: theme.palette.text.primary,
    },
  },
  input1: {
    width: '100%',
    height: 36,
    padding: '0 12px',
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 300,
    '&:focus': {
      background: 'transparent',
    },
  },
  requiredInput: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  required: {
    borderColor: theme.palette.text.primary,
  },
  adorn: {
    position: 'absolute',
    right: 0,
  },
  errorLabel: {
    position: 'absolute',
    left: 0,
    top: 37,
    color: theme.palette.error.main,
    fontSize: 11,
    lineHeight: '12px',
    minWidth: 116,
  },
  eye: {
    color: theme.palette.text.cancel,
    height: 18,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectInputHolder: {
    width: '100%',
    marginBottom: 30,
    '& label': {
      backgroundColor: 'transparent',
    },
  },
  selectInputHolderMb20: {
    width: '100%',
    marginBottom: 20,
    '& label': {
      backgroundColor: 'transparent',
    },
  },
  paperStyle: {
    backgroundColor: 'white',
  },
  arrowDownIcon: {
    position: 'absolute',
    top: 0,
    right: 14,
    zIndex: 0,
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
  inputOnSelect: {
    position: 'relative',
    zIndex: 1,
  },
  dateIcon: {
    position: 'absolute',
    top: 17,
    right: 14,
    zIndex: 0,
  },
  chkroot: {
    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
  chkrootchecked: {
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
});

export default styles;
