import { createSelector } from 'reselect';

export const getProductsState = state => state.products;
export const productSelector = state => state.products.list;
export const supplyReceiptsSelector = state => state.products.supplyreceipts;
export const productDetailSelector = state => state.products.detail;
export const productTypesSelector = state => state.products.prodTypes;
export const productInfoSelector = state => state.products.prodInfo;
export const vendorSelector = state => state.products.vendorData;
export const vendorOrdersSelector = state => state.products.vendorOrdersData;
export const vendorAutoCompleteSelector = state => state.products.autocompleteVendor;
export const carrierAutoCompleteSelector = state => state.products.autocompleteCarrier;
export const poAutoCompleteSelector = state => state.products.autocompletePO;
export const manufacturerSelector = state => state.products.manufacturerData;
export const manufacturerAutoCompleteSelector = state => state.products.autocompleteManufacturer;
export const productAutoCompleteSelector = state => state.products.autocompleteProduct;
export const filtersSelector = state => state.products.filters;
export const importInfoSelector = state => state.products.importinfo;
export const importProductsSelector = state => state.products.importproducts;
export const salesOrerSelector = state => state.products.salesorder;
export const salesOrderPageSelector = state => state.products.salesorderpage;
export const stockAlertSelector = state => state.products.stockalerts;
export const stockAlertRecipientSelector = state => state.products.stockalertrecipient;
export const srClientIdSelector = state => state.products.SRClientId;
export const activeClientsSelector = state => state.products.activeClients;
export const supplyrReceiptDetailSelector = state => state.products.supplyreceiptdetail;

export const getProductsData = createSelector([getProductsState], products => products.list);

export const getProductList = createSelector(
  productSelector,
  list => list,
);

export const getSupplyReceipts = createSelector(
  supplyReceiptsSelector,
  supplyreceipts => supplyreceipts,
);

export const getProductDetail = createSelector(
  productDetailSelector,
  detail => detail,
);

export const getProductTypes = createSelector(
  productTypesSelector,
  prodTypes => prodTypes,
);

export const getProductInfo = createSelector(
  productInfoSelector,
  prodInfo => prodInfo,
);

export const getVendorData = createSelector(
  vendorSelector,
  vendorData => vendorData,
);

export const getVendorOrdersData = createSelector(
  vendorOrdersSelector,
  vendorOrdersData => vendorOrdersData,
);

export const getAutoCompleteVendor = createSelector(
  vendorAutoCompleteSelector,
  autocompleteVendor => autocompleteVendor,
);

export const getAutoCompleteCarrier = createSelector(
  carrierAutoCompleteSelector,
  autocompleteCarrier => autocompleteCarrier,
);

export const getAutoCompletePO = createSelector(
  poAutoCompleteSelector,
  autocompletePO => autocompletePO,
);

export const getManufacturerData = createSelector(
  manufacturerSelector,
  manufacturerData => manufacturerData,
);

export const getAutoCompleteManufacturer = createSelector(
  manufacturerAutoCompleteSelector,
  autocompleteManufacturer => autocompleteManufacturer,
);

export const getAutoCompleteProduct = createSelector(
  productAutoCompleteSelector,
  autocompleteProduct => autocompleteProduct,
);

export const getFilters = createSelector(
  filtersSelector,
  filters => filters,
);

export const getimportInfo = createSelector(
  importInfoSelector,
  importinfo => importinfo,
);

export const getimportProducts = createSelector(
  importProductsSelector,
  importproducts => importproducts,
);

export const getSalesOrder = createSelector(
  salesOrerSelector,
  salesorder => salesorder,
);

export const getSalesOrderPage = createSelector(
  salesOrderPageSelector,
  salesorderpage => salesorderpage,
);

export const getStockAlerts = createSelector(
  stockAlertSelector,
  stockalerts => stockalerts,
);

export const getStockAlertRecipient = createSelector(
  stockAlertRecipientSelector,
  stockalertrecipient => stockalertrecipient,
);

export const getSupplyReceiptClientId = createSelector(
  srClientIdSelector,
  SRClientId => SRClientId,
);

export const activeClientsData = createSelector(
  activeClientsSelector,
  activeClients => activeClients,
);

export const getSupplyReceiptDetail = createSelector(
  supplyrReceiptDetailSelector,
  supplyreceiptdetail => supplyreceiptdetail,
);

