import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Slide } from '@material-ui/core';

const styles = theme => ({
  ...theme.classes,
  modal: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  getChildContext() {
    const { modalKey } = this.props;
    return {
      modalKey,
      handleClose: this.handleClose,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    const { modalKey, modalState } = nextProps;

    if (modalKey === modalState.modalKey) {
      return { show: modalState.showModal };
    }

    return { show: false };
  }

  handleClose = () => {
    const { closeAction, modalKey } = this.props;
    closeAction({ modalKey });
  }

  render() {
    const { show } = this.state;
    const { classes, children } = this.props;
    return (
      <Fragment>
        <Dialog
          fullScreen
          disableEscapeKeyDown
          disableBackdropClick
          open={show}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          classes={{
            paperFullScreen: classes.modal,
          }}
          BackdropProps={{
            root: classes.modal,
          }}
          PaperProps={{
            root: classes.modal,
          }}
        >
          {children}
        </Dialog>
      </Fragment>
    );
  }
}

Modal.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  closeAction: PropTypes.func.isRequired,
  modalKey: PropTypes.string.isRequired,
  modalState: PropTypes.shape({
    showModal: PropTypes.bool,
    modalKey: PropTypes.string,
  }).isRequired,
};

Modal.childContextTypes = {
  handleClose: PropTypes.func,
  modalKey: PropTypes.string,
};

export default withStyles(styles)(Modal);
