const styles = theme => ({
  ul: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '100px 0 0 30px',
    margin: 0,
    width: '100%',
    textAlign: 'left',
    '@media (max-width:1240px)': {
      padding: '18px 24px 0 24px',
    },
    '@media (max-width:374px)': {
      padding: '18px 24px 0 24px',
    },
  },
  li: {
    position: 'relative',
    padding: '0 0 28px',
    color: theme.palette.primary.main,
    '@media (max-width:1240px)': {
      width: '44%',
      display: 'inline-block',
      '&:nth-child(odd)': {
        width: '56%',
      },
      /* '&:nth-child(7)': {
        width: '100%',
        padding: '0 0 42px',
      }, */
    },
  },
  holiday: {
    position: 'absolute',
    bottom: 78,
    '@media (max-width:1240px)': {
      position: 'relative',
      bottom: 0,
      padding: '28px 0 28px',
      borderTop: '1px solid #C5D1E0',
      width: '56%',
    },
  },
  faq: {
    position: 'absolute',
    bottom: 30,
    '@media (max-width:1240px)': {
      position: 'relative',
      bottom: 0,
      padding: '28px 0 28px',
      borderTop: '1px solid #C5D1E0',
      width: '44% !important',
    },
  },
  /* faq: {
    position: 'relative',
    top: 254,
  },
  holiday: {
    position: 'relative',
    top: 254,
  }, */
  btn: {
    fontSize: 16,
    fontWeight: 400,
    height: 34,
    padding: '0 14px',
    borderRadius: '8px',
    boxShadow: 'none',
    textTransform: 'none',
  },
  navlink: {
    position: 'relative',
    padding: '0',
    borderRadius: '8px',
    boxShadow: 'none',
    display: 'inline-block',
    textDecoration: 'none',
    '& svg': {
      display: 'inline-block',
      fill: theme.palette.primary.light,
      verticalAlign: 'middle',
      marginRight: 18,
    },
    '& span': {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '14px',
      textTransform: 'none',
      display: 'inline-block',
      verticalAlign: 'middle',
      letterSpacing: 'inherit',
      '@media (max-width:1240px)': {
        fontSize: 14,
      },
      '@media (max-width:370px)': {
        fontSize: 12,
      },
    },
    '&[aria-current] svg': {
      fill: theme.palette.primary.main,
    },
    '&[aria-current] span': {
      color: theme.palette.primary.main,
      fontWeight: 800,
    },
  },
  menuactive: {
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 800,
    },
  },
  btnSelected: {
    fontWeight: 500,
  },
  btnIcon: {
    background: theme.palette.secondary.main,
    position: 'absolute',
    left: 0,
    top: 7,
    width: 4,
    height: 20,
    borderRadius: '4px',
    display: 'none',
  },
  visibility: {
    position: 'absolute',
    top: 6,
    right: 46,
    width: 15,
    display: 'none',
  },
  calendarbadge: {
    position: 'absolute',
    top: 6,
    right: -12,
    '& span': {
      backgroundColor: `${theme.palette.text.dot} !important`,
      color: '#fff !important',
      fontSize: 11,
      fontWeight: '500 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 16,
      height: 16,
    },
  },
});

export default styles;
