const styles = theme => ({
  thead: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 0px 6px 0px rgba(68, 107, 176, 0.91), 0px 10px 6px 0px rgba(68, 107, 176, 0.51)',
    borderRadius: 6,
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  theadstatic: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  thtrStyle: {
    height: 44,
    '& th': {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    '& th[aria-sort="ascending"] span': {
      color: '#2C3033',
    },
    '& th[aria-sort="descending"] span': {
      color: '#2C3033',
    },
    '& th:first-child': {
      borderTopLeftRadius: 6,
    },
    '& th:last-child': {
      borderTopRightRadius: 6,
    },
  },
  thtrstatic: {
    height: 44,
    '& th': {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    '& th[aria-sort="ascending"] span': {
      color: '#2C3033',
    },
    '& th[aria-sort="descending"] span': {
      color: '#2C3033',
    },
  },
  tblsortlbl: {
    whiteSpace: 'nowrap',
    '&.email': {
      color: '#fff',
      cursor: 'default',
    },
    '& svg': {
      width: 9,
      fill: '#446BB0',
      marginLeft: 10,
    },
  },
  tblsortinglbl: {
    whiteSpace: 'nowrap',
    cursor: 'default',
    '& svg': {
      fill: '#446BB0',
      marginLeft: 10,
      height: 12,
    },
    '&:hover, &:focus': {
      color: 'inherit',
    },
  },
  tblsortlbldisabled: {
    whiteSpace: 'nowrap',
    cursor: 'default',
    color: '#ddd',
    '& svg': {
      fill: '#446BB0',
      marginLeft: 10,
      height: 12,
    },
    '&:hover, &:focus': {
      color: '#ddd',
    },
  },
  tg1: {
    padding: '4px 0 4px 29px',
    '@media (max-width:1350px)': {
      padding: '4px 0 4px 12px',
    },
  },
  tg2: {
    padding: '4px 20px',
  },
  tglast: {
    padding: '0 !important',
    width: 0,
  },
});

export default styles;
