import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Dropdown } from 'resources/icons/dropdown.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grow, Paper, Popper, Typography, List, ListItem, ListItemText, InputBase, CircularProgress } from '@material-ui/core';
import { ReactComponent as Check } from 'resources/icons/check.svg';
import { ReactComponent as CloseIcon } from 'resources/icons/close.svg';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import styles from './HeaderDropMenuStyle';

class HeaderDropMenu extends Component {
  state = {
    open: false,
    searchtxt: '',
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ ...this.state, open: false });
  };

  openMobileMenu = (e) => {
    e.stopPropagation();
    const { actions } = this.props;
    const modalKey = 'mobile-menu';
    const params = { type: 'list' };
    actions.ui.openModal({ modalKey, params });
  };

  beginSearch = (e) => {
    if (e.charCode === 13) {
      this.handleSearch();
    }
  }

  handleClear = () => {
    const { resetSearchClient } = this.props;
    resetSearchClient();
    this.setState({ ...this.state, searchtxt: '' });
  }

  handleSearch = () => {
    const { searchtxt } = this.state;
    const { searchClient, setloadtype } = this.props;
    if (searchtxt.trim() === '') {
      const { resetSearchClient } = this.props;
      resetSearchClient();
      setloadtype('');
    } else {
      searchClient(searchtxt, 10, 1);
      setloadtype('search client');
    }
  }

  updateTxtSearch = (txt) => {
    this.setState({ ...this.state, searchtxt: txt });
  }

  handleClient = (e) => {
    const { setClientId, setGraphClientId, setloadtype } = this.props;
    setClientId(e.currentTarget.dataset.client);
    setGraphClientId(parseInt(e.currentTarget.dataset.client, 10));
    setloadtype('getclients');
    this.setState({ open: false });
  };

  render() {
    const {
      classes, location, clients, clientId, getloadtype, actionloading, clientlist,
    } = this.props;
    const { open, searchtxt } = this.state;

    const loc = location.pathname.split('/', 4, 0);
    let title = '';
    switch (loc[1]) {
      case '':
        title = 'Dashboard';
        break;
      case 'order-fulfillment':
      case 'pending-orders':
      case 'shipped-orders':
      case 'returned-orders':
      case 'return-authorization':
      case 'third-party':
        title = 'Order Fulfillment';
        break;
      case 'purchase-orders':
        title = 'Purchase Orders';
        break;
      case 'supply-receipts':
        title = 'Supply Receipts';
        break;
      case 'support':
        title = 'Support';
        break;
      case 'calendar':
        title = 'Mange Calendar';
        break;
      default:
        break;
    }

    let clientName = 'All Clients';
    if (Object.keys(clients).length) {
      const clientselected = clients.filter(client => client.attributes.id === clientId);
      if (clientselected.length) {
        clientName = clientselected[0].attributes.client_name;
      }
    }

    if (typeof clientlist !== 'undefined' && clientlist.length) {
      const clientselected = clientlist.filter(client => client.id === clientId);
      if (clientselected.length) {
        clientName = clientselected[0].client_name;
      }
    }

    let searchloader = false;
    if (actionloading && getloadtype === 'search client') {
      searchloader = true;
    }

    return (
      <Fragment>
        <List className={classes.ucontainer}>
          <ListItem
            className={classes.uitem}
            button
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
          >
            { !open ?
              <Typography className={classes.title}>{title}:</Typography>
            : null }
            <ListItemText
              className={classes.uitemtext}
              primary={
                <>
                  <Typography className={classes.clienttitle} component="span">
                    {clientName}
                    <Dropdown className={classes.dropdown} />
                  </Typography>
                </>
              }
            />
          </ListItem>
        </List>
        <ClickAwayListener onClickAway={this.handleClose}>
          <div className={classes.popperbox} data-title={title}>
            <Popper
              open={open}
              anchorEl={this.anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={classes.paper}>
                    <div className={classes.searchbox}>
                      <InputBase
                        placeholder="Search Clients"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        autoFocus
                        inputProps={{ 'aria-label': 'Search Clients' }}
                        onKeyPress={e => this.beginSearch(e)}
                        onChange={(event) => { // adding the onChange event
                          this.updateTxtSearch(event.target.value);
                        }}
                        value={searchtxt}
                      />
                      { !searchloader ?
                        <>
                          { typeof clientlist !== 'undefined' && clientlist.length ?
                            <div className={`clear ${classes.absearchicon}`} onClick={this.handleClear}>
                              <CloseIcon />
                            </div>
                          :
                            <div className={classes.absearchicon} onClick={this.handleSearch}>
                              <SearchIcon />
                            </div>
                          }
                        </>
                      :
                        <div className={classes.absearchicon}>
                          <div className="loader">
                            <CircularProgress
                              size={24}
                              className={`${classes.buttonProgress} ${classes.loadMoreProgress}`}
                            />
                          </div>
                        </div>
                      }
                    </div>
                    { typeof clientlist !== 'undefined' && clientlist.length ?
                      <List className={classes.ulistcontainer}>
                        { clientlist.map(client => (
                          <ListItem
                            key={client.id}
                            className={`${client.id === clientId ? 'active' : ''} ${classes.button}`}
                          >
                            <Typography
                              onClick={this.handleClient}
                              data-client={client.id}
                            >
                              {client.client_name} <Check />
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    :
                      <List className={classes.ulistcontainer}>
                        <ListItem
                          className={`${clientId === 0 ? 'active' : ''} ${classes.button}`}
                        >
                          <Typography
                            onClick={this.handleClient}
                            data-client="0"
                          >
                            All Clients <Check />
                          </Typography>
                        </ListItem>
                        { clients.map(client => (
                          <ListItem
                            key={client.attributes.id}
                            className={`${client.attributes.id === clientId ? 'active' : ''} ${classes.button}`}
                          >
                            <Typography
                              onClick={this.handleClient}
                              data-client={client.attributes.id}
                            >
                              {client.attributes.client_name} <Check />
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    }
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
      </Fragment>
    );
  }
}

HeaderDropMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  clients: PropTypes.instanceOf(Object).isRequired,
  clientId: PropTypes.number.isRequired,
  setClientId: PropTypes.func.isRequired,
  searchClient: PropTypes.func, // eslint-disable-line
  setGraphClientId: PropTypes.func, // eslint-disable-line
  resetSearchClient: PropTypes.func, // eslint-disable-line
  clientlist: PropTypes.array, // eslint-disable-line
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(HeaderDropMenu);
