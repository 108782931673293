const style = () => ({
  headdropmobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      backgroundColor: '#fff',
      display: 'block',
      marginTop: -45,
    },
  },
  btnswrapper: {
    margin: '-54px 0 54px',
    width: 380,
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  returnbtn: {
    backgroundColor: '#fff',
    color: '#949DB2',
    height: 30,
    minHeight: 30,
    width: 120,
    borderRadius: 100,
    boxShadow: 'none',
    fontSize: 12,
    fontWeight: 500,
    border: '1px solid #C5D1E0',
    textTransform: 'capitalize',
    '&.active': {
      backgroundColor: '#09B0EE',
      color: '#fff',
      border: 0,
    },
    '&:hover': {
      boxShadow: 0,
    },
  },
});

export default style;
