import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Holiday } from 'resources/icons/holiday.svg';
/* import { ReactComponent as SettingsIcon } from 'resources/icons/iconsettings.svg'; */
import styles from './LeftMenuStyle';

function LeftMenu(props) {
  const {
    classes, wmsMenus, location,
  } = props;
  const loc = location.pathname.split('/', 4, 0);
  let menu = '';
  switch (loc[1]) {
    case '':
      menu = 'Dashboard';
      break;
    case 'pending-orders':
    case 'shipped-orders':
    case 'returned-orders':
    case 'return-authorization':
    case 'third-party':
      menu = 'Order Fulfillment';
      break;
    case 'manage-clients':
    case 'manage-access':
      menu = 'Settings';
      break;
    default:
      break;
  }

  const close = () => {
    const match = window.matchMedia('(max-width: 1240px)');
    if (match.matches) {
      const { actions } = props;
      const modalKey = 'mobile-menu';
      actions.ui.closeModal({ modalKey });
    }
  };

  return (
    <div>
      <ul className={classes.ul}>
        {wmsMenus.map(item => (
          <li className={classes.li} key={item.label}>
            <NavLink
              className={classNames(
                  classes.navlink,
                  menu === item.label ?
                  classes.menuactive
                  : '',
                  )}
              to={item.url}
              exact
              onClick={close}
            >
              {item.icon}
              <Typography variant="overline">
                {item.label}
              </Typography>
            </NavLink>
          </li>
        ))}
        <li className={classNames(classes.li, classes.holiday)}>
          <NavLink className={classes.navlink} to="/calendar" exact onClick={close} >
            <Holiday />
            <Typography variant="overline">
              Manage Calendar
            </Typography>
          </NavLink>
        </li>
        <li className={classNames(classes.li, classes.faq)}>&nbsp;</li>
        {/* <li className={classNames(classes.li, classes.faq)}>
          <NavLink
            className={classNames(
              classes.navlink,
              menu === 'Settings' ?
              classes.menuactive
              : '',
              )}
            to="/manage-clients"
            exact
            onClick={close}
          >
            <SettingsIcon />
            <Typography variant="overline">
              Settings
            </Typography>
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

LeftMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  wmsMenus: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object), // eslint-disable-line
};

export default withRouter(withStyles(styles)(LeftMenu));
