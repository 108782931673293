const styles = theme => ({
  menu: {
    textTransform: 'inherit',
    color: theme.palette.primary.main,
    padding: 0,
    '& span': {
      fontSize: '16px !important',
      fontWeight: '500 !important',
      lineHeight: '18px',
    },
    '& svg': {
      marginLeft: 12,
    },
  },
  htitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '18px',
    color: theme.palette.secondary.main,
  },
});

export default styles;
