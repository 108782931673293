const styles = theme => ({
  ...theme.classes,
  paper: {
    backgroundColor: 'transparent',
    width: 300,
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    borderRadius: 6,
    padding: '36px 0 0 0 !important',
  },
  menuButton: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'inline-block',
      marginRight: -12,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  ucontainer: {
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
    height: 60,
    minWidth: 288,
    '@media (max-width:1240px)': {
      paddingLeft: 4,
    },
  },
  uitem: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  uitemtext: {
    padding: '0 15px',
    '& span': {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.secondary.main,
    },
  },
  clienttitle: {
    position: 'relative',
    paddingRight: 20,
    display: 'inline-block',
  },
  avatar: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    letterSpacing: 1,
    fontSize: 14,
    fontWeight: 600,
  },
  avatarShadow: {
    boxShadow: '0 1px 16px rgba(150, 163, 175, 0.84)',
  },
  dropdown: {
    position: 'absolute',
    top: '48%',
    right: 2,
    fill: theme.palette.header.primary,
  },
  ulistcontainer: {
    backgroundColor: '#fff',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    padding: '0 20px 28px',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    height: 332,
    overflowX: 'scroll',
    '& li': {
      borderBottom: '1px solid #E8EAEE',
      padding: 0,
    },
    '& li svg': {
      display: 'none',
    },
    '& li.active svg': {
      display: 'inline-block',
    },
    '& p': {
      fontSize: 14,
      lineHeight: '19px',
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: '10px 10px',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& li.active a': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  listhead: {
    padding: '8px 12px !important',
    border: '0 none !important',
    '& p': {
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 500,
    },
  },
  ulistitem: {
    position: 'relative',
    /* cursor: 'pointer', */
    padding: '26px 26px !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& p': {
      cursor: 'auto',
    },
  },
  ulistitemtext: {
    padding: '0 0 0 15px',
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '16px',
      color: theme.palette.text.primary,
      marginBottom: '2px',
    },
    '& p': {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '14px',
      color: theme.palette.primary.light,
    },
  },
  wh44: {
    width: 44,
    height: 44,
    fontSize: 22,
  },
  wh65: {
    width: 65,
    height: 65,
    fontSize: 22,
  },
  avwrapper: {
    display: 'inline-block',
  },
  popperbox: {
    '& div[role="tooltip"]': {
      top: '-42px !important',
    },
    '@media (max-width:1240px)': {
      '& div[role="tooltip"]': {
        left: '2px !important',
        zIndex: 11111,
      },
      '&[data-title="Order Fulfillment"] div[role="tooltip"]': {
        left: '2px !important',
      },
      '&[data-title="Purchase Orders"] div[role="tooltip"]': {
        left: '2px !important',
      },
      '&[data-title="Supply Receipts"] div[role="tooltip"]': {
        left: '2px !important',
      },
    },
  },
  searchbox: {
    backgroundColor: '#fff',
    position: 'relative',
    padding: '24px 18px 20px',
  },
  inputRoot: {
    border: '1px solid #E4E4E4',
    borderRadius: 4,
    width: '100%',
  },
  inputInput: {
    fontSize: 12,
    padding: '0 12px',
    height: 36,
  },
  absearchicon: {
    position: 'absolute',
    top: 31,
    right: 26,
    cursor: 'pointer',
    '& svg': {
      fill: theme.palette.secondary.main,
      transform: 'rotate(90deg)',
    },
    '& .loader': {
      position: 'relative',
      marginTop: 12,
    },
    '&.clear svg': {
      stroke: '#949db2',
      width: 20,
      height: 20,
      padding: 4,
      marginTop: 2,
    },
  },
});

export default styles;
