import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import GridTableMobileStyle from 'ui/components/common/wms/GridTableMobileStyle';
import styles from './DetailsStyle';

class Details extends Component {
  render() {
    const { classes, detail } = this.props;
    const data = detail.attributes;
    if (typeof data.recipient === 'undefined') {
      return true;
    }
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.top}>
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.nameadd} item xs={6}>
                <Typography className={classes.name}>{data.recipient.recipient_name}</Typography>
                <Typography className={classes.address}>
                  {data.recipient.shipping_address1}<br />
                  {data.recipient.shipping_city}, {data.recipient.shipping_state},<br />
                  {data.recipient.shipping_zip}, {data.recipient.shipping_country_code}
                </Typography>
              </Grid>
              <Grid className={classes.phoneadd} item xs={6}>
                <div className={classes.rightcontainer}>
                  <Typography className={classes.gridcaption}>Phone:</Typography>
                  { data.recipient.recipient_phone !== null && data.recipient.recipient_phone !== '' ?
                    <Typography className={classNames(classes.gridbody, classes.gridbody2, classes.mb18)} variant="body1">{data.recipient.recipient_phone}</Typography>
                  :
                    <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                  }
                </div>
                <div className={classes.rightcontainer}>
                  <Typography className={classes.gridcaption}>Email:</Typography>
                  { data.recipient.recipient_email !== null && data.recipient.recipient_email !== '' ?
                    <Typography className={classNames(classes.gridbody, classes.gridbody2)} variant="body1">{data.recipient.recipient_email}</Typography>
                  :
                    <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.mid}>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.div100} item xs={5}>
                <Typography className={classes.gridcaption}>Order ID</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_no}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divleft}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">Carrier</Typography>
                { data.carrier_code !== null && data.carrier_code !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.carrier_code}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`${classes.div50} ${classes.divright}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Tracking Number</Typography>
                { data.tracking_no !== null && data.tracking_no !== '' ?
                  <>
                    { data.tracking_url !== '' ?
                      <a
                        className={`${classes.gridbody} ${classes.medium} ${classes.trackno}`}
                        href={data.tracking_url}
                        target="blank"
                      >
                        {data.tracking_no}
                      </a>
                    :
                      <Typography className={`${classes.gridbody} ${classes.medium}`} variant="body1">{data.tracking_no}</Typography>
                    }
                  </>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>

              {/* <Grid className={`${classes.div50} ${classes.divleft}`} item xs={4}>
                <Typography className={classes.gridcaption}
                variant="caption">Order Date</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)}
                variant="body1">{data.ordered_at2}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divright}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Status</Typography>
                <Typography
                  className={classNames(classes.gridbody, classes.medium, classes.status)}
                  variant="body1"
                  data-status={data.order_status_text}
                >
                  {data.order_status_text}
                </Typography>
              </Grid> */}
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={`${classes.div50} ${classes.divbottomleft}`} item xs={5}>
                <Typography className={classes.gridcaption} variant="caption">Order Date</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.created_at2}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divbottomright}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">Status</Typography>
                <Typography
                  className={classNames(classes.gridbody, classes.medium, classes.status)}
                  variant="body1"
                  data-status={data.order_status_text}
                >
                  {data.order_status_text === 'pending' ? 'processing' : data.order_status_text}
                </Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divtopleft}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">3rd Party Billing</Typography>
                { data.thirdparty_billing_acct_no !== null && data.thirdparty_billing_acct_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.thirdparty_billing_acct_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`inlineblock ${classes.div50} ${classes.divtopright} ${classes.hideondesktop}`} item xs={12}>
                <Typography className={classes.gridcaption} variant="caption">PO Number</Typography>
                { data.po_no !== null && data.po_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.po_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={`${classes.gridcontainer} ${classes.hideonmobile}`} container spacing={0}>
              <Grid className={classes.div100bottom} item xs={12}>
                <Typography className={classes.gridcaption} variant="caption">PO Number</Typography>
                { data.po_no !== null && data.po_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.po_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={`${classes.divider} ${classes.hideonmobile}`} />
          </div>
          { data.items !== null ?
            <>
              <Typography className={classes.t1}>Items Ordered:</Typography>
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow className={classes.trow}>
                    <TableCell className="skudesc" align="left">SKU<span>/Description</span></TableCell>
                    <TableCell className="desc">Description</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Unit Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  { data.items.length ?
                    <>
                      { data.items.map(item => (
                        <TableRow className={classes.trow} key={item.sort_no}>
                          <TableCell align="left">
                            <Typography className={classes.medium} color="primary">{item.sku}</Typography>
                            <Typography className="description" color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell className="desc" align="left">
                            <Typography className={classes.medium} color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.medium} color="primary">{item.quantity}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.medium} color="primary">{item.unit_price}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  :
                    <TableRow className={classes.trow}>
                      <TableCell align="center" colSpan="4">
                        <Typography className={classes.medium} color="primary">No results found.</Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </>
          : null }
        </div>
      </div>
    );
  }
}

Details.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(theme => ({
  ...styles(theme),
  ...GridTableMobileStyle(theme),
}))(Details);

