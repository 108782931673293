import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography/* , Button, CircularProgress */ } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as SyncBtn } from 'resources/icons/sync-btn.svg';
import SnackBarNotify from './SnackBarNotify';
import styles from './UpdateSyncBtnStyle';

class UpdateSyncBtn extends Component {
  state = {
    loading: false,
    limit: 20,
    page: 1,
  };

  componentDidMount() {
    this.getImportsInfo();
  }

  componentDidUpdate() {
    const {
      actionloading, getloadtype, setloadtype, getimportorders, setSortColumn, setSortType,
    } = this.props;
    if (!actionloading && (getloadtype === 'updating products' || getloadtype === 'updating orders')) {
      // console.log(getimportorders); // eslint-disable-line
      if (getloadtype === 'updating products') {
        this.child.showMessage('Successfully updated products!', 'success');
      }
      if (getloadtype === 'updating orders') {
        if (!getimportorders.attributes.wms_orders.pending_orders.length
          && !getimportorders.attributes.wms_orders.shipped_orders.length) {
          this.child.showMessage('Orders are up to date', 'success');
        } else {
          this.child.showMessage('Successfully updated orders!', 'success');
        }
      }

      setloadtype('');
      setTimeout(() => {
        this.setState({ ...this.state, loading: false });
      }, 1);
      const {
        location, getProductList, orderList, returnOrderList,
      } = this.props;
      const { limit, page } = this.state;
      switch (location.pathname) {
        case '/products-out-of-stocks':
          getProductList(limit, page, 2, null, null, 'description', 'asc');
          break;
        case '/products':
        case '/top-selling-products':
          getProductList(10, 1, null, null, null, 'description', 'asc');
          break;
        case '/products-low-in-stocks':
          getProductList(limit, page, null, null, null, 'quantity', 'asc');
          break;
        case '/orders':
          orderList(-1, limit, page, null, null, null, null, null, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
          break;
        case '/pending-orders':
          orderList(1, limit, page, null, null, null, null, null, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
          break;
        case '/shipped-orders':
          orderList(4, limit, page, null, null, null, null, null, 'ordered_at', 'desc');
          setSortColumn('ordered_at2');
          setSortType('desc');
          break;
        case '/returned-orders':
          returnOrderList(limit, page);
          break;
        default:
          break;
      }
      setTimeout(() => {
        this.getImportsInfo();
      }, 60);
      setloadtype('search');
    }
    if (!actionloading && getloadtype === 'You can only import once a day') {
      this.child.showMessage('You can only import once a day', 'error');
      setloadtype('');
      setTimeout(() => {
        this.setState({ ...this.state, loading: false });
      }, 1);
    }
  }

  onUpdate = () => {
    const {
      importProducts, importOrders, setloadtype, location,
    } = this.props;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        setloadtype('updating products');
        importProducts();
        break;
      case '/orders':
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
        setloadtype('updating orders');
        importOrders();
        break;
      default:
        break;
    }
    this.setState({ ...this.state, loading: true });
  };

  getImportsInfo = () => {
    const { location, importsInfo } = this.props;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
      case '/orders':
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
        importsInfo();
        break;
      default:
        break;
    }
  }

  render() {
    const { classes, getimportinfo, displayfor/* , location */ } = this.props;
    if (typeof getimportinfo === 'undefined') {
      return true;
    }
    if (Object.keys(getimportinfo).length === 0) {
      return true;
    }
    const { loading } = this.state;
    /* let btnlbl;
    switch (location.pathname) {
      case '/products':
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
        btnlbl = 'Update';
        break;
      case '/orders':
      case '/shipped-orders':
      case '/pending-orders':
      case '/returned-orders':
        btnlbl = 'Update';
        break;
      default:
        break;
    } */
    return (
      <Fragment>
        { getimportinfo.attributes !== null ?
          <Typography className={`lastupdate ${classes.lastupdate}`}>
            Last updated {getimportinfo.attributes.date_last_updated2}
          </Typography>
        : null }
        {/* <div className={`btndesktop submitWrapper ${classes.submitWrapper}`}>
          <Button
            className={`${classes.modalBtn} ${classes.modalBtn2}`}
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={this.onUpdate}
          >
            {btnlbl}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div> */}
        <SyncBtn className={`${displayfor} syncbtn ${loading ? classes.rotating : ''}`} onClick={this.onUpdate} />
        <SnackBarNotify onRef={(instance) => { this.child = instance; }} />
      </Fragment>
    );
  }
}

UpdateSyncBtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  importProducts: PropTypes.func, // eslint-disable-line
  importOrders: PropTypes.func, // eslint-disable-line
  importsInfo: PropTypes.func, // eslint-disable-line
  getimportinfo: PropTypes.object, // eslint-disable-line
  /* getimportproducts: PropTypes.object, // eslint-disable-line */
  getimportorders: PropTypes.object, // eslint-disable-line
  getProductList: PropTypes.func, // eslint-disable-line
  orderList: PropTypes.func, // eslint-disable-line
  returnOrderList: PropTypes.func, // eslint-disable-line
  setSortColumn: PropTypes.func, // eslint-disable-line
  setSortType: PropTypes.func, // eslint-disable-line
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  displayfor: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(UpdateSyncBtn);
