/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, NoSsr, TextField, Paper, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { ReactComponent as Abc } from 'resources/icons/abc.svg';

const styles = theme => ({
  ...theme.classes,
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px`,
    color: theme.palette.grey[700],
    fontSize: 13,
    textAlign: 'left',
  },
  singleValue: {
    fontSize: 14,
    lineHeight: '14px',
  },
  placeholder: {
    position: 'absolute',
    left: 12,
    fontSize: 14,
    color: theme.palette.text.placeholder,
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  paperdown: {
    bottom: 50,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  inputRoot: {
    backgroundColor: '#fff',
    padding: 0,
    '& $notchedOutline': {
      borderColor: '#5D5B5B',
      borderWidth: 1,
    },
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cssShrink: {
    backgroundColor: 'transparent',
    color: '#96A3AF',
    marginTop: 0,
  },
  cssFocused: {},
  notchedOutline: {},
  input1: {
    width: '100%',
    height: 36,
    padding: '0 10px',
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 300,
    '&:focus': {
      background: 'transparent',
    },
  },
  dateInput: {
    padding: '0 2px 0 10px',
  },
  cssLabel: {
    backgroundColor: '#fff',
    color: '#96A3AF',
    marginTop: -10,
    left: -2,
    fontSize: 14,
    height: '50%',
    minWidth: 82,
    '&$cssFocused': {
      color: '#96A3AF',
      marginTop: 0,
    },
  },
  noOption: {
    textAlign: 'right',
    width: '100%',
    padding: '10px 20px',
  },
  abcIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    fontStyle: 'normal',
    zIndex: 0,
  },
  inputOnSelect: {
    position: 'relative',
    zIndex: 1,
  },
  autoCompleteStyle: {
    '& label': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  loader: {
    color: '#446bb0',
    position: 'absolute',
    top: '50%',
    right: 8,
    left: 'inherit',
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    bacground: '#fff',
    position: 'relative',
    display: 'inline-block',
  },
});

function NoOptionsMessage(props) {
  const { selectProps } = props;
  /* console.log(props.selectProps.textFieldProps); // eslint-disable-line */
  let loading = false;
  if (props.selectProps.textFieldProps.loading === 'true') {
    loading = true;
  }
  let lbl = '';
  let dispbtn = false;
  switch (props.selectProps.textFieldProps.label) {
    case 'Recipient Name *':
      dispbtn = false;
      lbl = 'Add New Recipient Name';
      break;
    case 'Vendor Contact Name *':
      dispbtn = true;
      lbl = 'Add New Vendor Contact Name';
      break;
    case 'Vendor Name *':
      dispbtn = true;
      lbl = 'Add New Vendor Name';
      break;
    case 'Vendor Name':
      dispbtn = false;
      lbl = 'Type in Vendor Name';
      break;
    case 'Recipient Name':
      dispbtn = false;
      lbl = 'Type in Recipient Name';
      break;
    case 'Client Name *':
      dispbtn = false;
      lbl = 'Type in Client Name';
      break;
    case 'Type SKU or Description *':
      dispbtn = false;
      lbl = 'Type in SKU or Description';
      break;
    case 'Type SKU or Description':
      dispbtn = false;
      lbl = 'Type in SKU or Description';
      break;
    case 'P.O. Number':
      dispbtn = false;
      lbl = 'Type in P.O. Number';
      break;
    case 'Vendor *':
      dispbtn = false;
      lbl = 'Type in Vendor';
      break;
    case 'Manufacturer':
      dispbtn = true;
      lbl = 'Add New';
      break;
    default:
      dispbtn = false;
      break;
  }
  return (
    <div className={props.selectProps.classes.noOption}>
      { dispbtn ?
        <div className={props.selectProps.classes.btnWrapper}>
          <Button
            className={props.selectProps.classes.modalBtn}
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={event => selectProps.onChange({
                event, addNew: true, newValue: selectProps.inputValue,
              })}
          >
            {lbl}
          </Button>
          {
            loading &&
            <CircularProgress size={24} className={props.selectProps.classes.buttonProgress} />
          }
        </div>
        :
        <Typography
          color="textSecondary"
          className={props.selectProps.classes.noOptionsMessage}
          {...props.innerProps}
        >
          {lbl !== '' ? lbl : props.children}
        </Typography>
      }
    </div>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      className="autocomplete"
      InputLabelProps={{
        classes: {
          root: classNames(props.selectProps.classes.cssLabel),
          focused: props.selectProps.classes.cssFocused,
          shrink: props.selectProps.classes.cssShrink,
        },
      }}
      InputProps={{
        inputComponent,
        classes: {
          root: props.selectProps.classes.inputRoot,
          input: classNames(props.selectProps.classes.input1, props.selectProps.classes.dateInput),
          focused: props.selectProps.classes.cssFocused,
          notchedOutline: props.selectProps.classes.notchedOutline,
        },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={`singleValue ${props.selectProps.classes.singleValue}`} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper
      square
      className={`${props.selectProps.classes.paper} ${props.selectProps.textFieldProps.dropdownposition === 'bottom' ? props.selectProps.classes.paperdown : ''}`}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function arrowRenderer() {
  return (
    <span>+</span>
  );
}

function IndicatorSeparator() {
  return null;
}

const componentlist = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  arrowRenderer,
  IndicatorSeparator,
};

class TextAutoComplete extends Component {
  state = {
    single: null,
    loading: false,
    btnLoading: false,
    fetching: false,
    processing: false,
  };

  componentWillMount() {
    const { config } = this.props;
    /* console.log(config); // eslint-disable-line */
    if (typeof config.editing !== 'undefined' && config.editing) {
      this.setSingleValue({ label: config.options[0].value, value: config.options[0].key });
    }
  }

  componentDidUpdate() {
    const { loadstatus, loadbtnstatus } = this.props;
    if (this.state.fetching) {
      if (!loadstatus) {
        this.loadfetch();
      }
    }
    if (this.state.processing) {
      if (!loadbtnstatus) {
        this.loadbtnset();
      }
    }
  }

  setSingleValue = (single) => {
    this.setState({
      ...this.state,
      single,
    });
  }

  loadfetch = () => {
    this.setState({
      ...this.state,
      loading: false,
      fetching: false,
    });
  }

  loadbtnset = () => {
    this.setState({
      ...this.state,
      btnLoading: false,
      processing: false,
    });
  }

  handleChange = name => (value) => {
    const { addNewClicked, hasfunction } = this.props;
    let btnLoading = false;
    if (value !== null) {
      if (typeof value.addNew !== 'undefined' && value.addNew) {
        addNewClicked(value.addNew, value.newValue);
        btnLoading = true;
      }
      if (hasfunction) {
        if (value.value !== null && typeof value.value !== 'undefined') {
          addNewClicked(false, value.value);
          btnLoading = false;
        }
      }
    } else {
      addNewClicked(false, '');
    }
    /* console.log(value); // eslint-disable-line */
    this.setState({
      ...this.state,
      btnLoading,
      processing: true,
      [name]: value,
    });
  };

  timer = null;

  handleInputChange = (e) => {
    clearTimeout(this.timer);
    const val = e.target.value;
    this.timer = setTimeout(() => { this.triggerChange(val); }, 310);
  }

  triggerChange = (targetValue) => {
    const { onChange } = this.props;
    onChange(targetValue);
    if (targetValue !== '') {
      this.setState({
        ...this.state,
        loading: true,
        fetching: true,
      });
    }
  }

  iconDisplay = () => {
    const { loading } = this.state;
    const { classes } = this.props;
    if (loading) {
      return (
        <CircularProgress size={24} className={classes.loader} />
      );
    }
    return (
      <i className={classes.abcIcon}>
        <Abc />
      </i>
    );
  }

  render() {
    const {
      classes, theme, config, disabled, dropdownposition,
    } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
        '& label': {
          color: '#96A3AF',
        },
      }),
    };
    let suggestions = [];
    if (typeof config.options !== 'undefined') {
      suggestions = config.options.map(suggestion => ({
        value: suggestion.key,
        label: suggestion.value,
      }));
    }
    /* console.log(suggestions); // eslint-disable-line */

    const complist = {
      ...componentlist,
      DropdownIndicator: this.iconDisplay,
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            className={classes.autoCompleteStyle}
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            components={complist}
            value={this.state.single}
            onChange={this.handleChange('single')}
            isClearable
            placeholder=""
            textFieldProps={{
              label: config.label,
              loading: this.state.btnLoading.toString(),
              InputLabelProps: {
                shrink: true,
              },
              onChange: (e) => {
                this.handleInputChange(e);
              },
              disabled: typeof disabled !== 'undefined',
              dropdownposition: typeof dropdownposition !== 'undefined' ? dropdownposition : 'top',
            }}
            SelectProps={{
              isCreatable: true,
              msgNoOptionsMatchFilter: 'none',
            }}
          />
        </NoSsr>
      </div>
    );
  }
}

TextAutoComplete.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  theme: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles, { withTheme: true })(TextAutoComplete);
