import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
/* import { ReactComponent as Filter } from 'resources/icons/filter.svg'; */
import { Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './FilterStyle';

class FilterDisplay extends Component {
  handleDelete = (e, val) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = filtersdata
      .filter(data => data.id !== val)
      .map(dfilter => (dfilter));
    setfilters(filterItems);
    setloadtype('filter updated');
  }
  render() {
    const { classes, filtersdata, dispstyle } = this.props;
    return (
      <div className={`filters ${classes.fdisp} ${typeof filtersdata !== 'undefined' && filtersdata.length ? classes.fdisp2 : ''} ${typeof dispstyle !== 'undefined' ? dispstyle : ''}`}>
        { typeof filtersdata !== 'undefined' && filtersdata !== null && filtersdata.length ?
          <Fragment>
            <Typography className={classes.filterlbl} component="label">Filters: </Typography>
            { filtersdata.map((filter) => {
              let orderDateStart;
              let orderDateEnd;
              if (typeof filter.order_date_start !== 'undefined' && filter.order_date_start !== '') {
                const ods = filter.order_date_start.split('-');
                orderDateStart = `${ods[1]}-${ods[2]}-${ods[0]}`;
              }
              if (typeof filter.order_date_end !== 'undefined' && filter.order_date_end !== '') {
                const ode = filter.order_date_end.split('-');
                orderDateEnd = `${ode[1]}-${ode[2]}-${ode[0]}`;
              }
              return (
                <div className={classes.fwrapper} key={filter.id}>
                  { typeof filter.vendor_name !== 'undefined' && filter.vendor_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          VENDOR: <span>{filter.vendor_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.order_date_start !== 'undefined' && filter.order_date_start !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          From: <span>{orderDateStart}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.order_date_end !== 'undefined' && filter.order_date_end !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          To: <span>{orderDateEnd}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.recipient_name !== 'undefined' && filter.recipient_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          RECIPIENT: <span>{filter.recipient_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.company_name !== 'undefined' && filter.company_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          COMPANY: <span>{filter.company_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.recipient_email !== 'undefined' && filter.recipient_email !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          EMAIL: <span>{filter.recipient_email}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.status !== 'undefined' && filter.status !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          STATUS: <span>{filter.status}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.carrier_code !== 'undefined' && filter.carrier_code !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          CARRIER: <span>{filter.carrier_code}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                </div>
              );
            })}
          </Fragment>
        : null }
      </div>
    );
  }
}

FilterDisplay.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  filtersdata: PropTypes.array, // eslint-disable-line
  setfilters: PropTypes.func, // eslint-disable-line
  setloadtype: PropTypes.func, // eslint-disable-line
  dispstyle: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(FilterDisplay);
