/* eslint-disable max-len */
import { makeRequest, METHODS } from '@entando/apimanager';
/* import { getProdTypes } from 'test/mocks/products'; */
import { getPurchaseOrderData } from 'mocks/products';
import axios from 'axios';
import { wmsheader } from 'state/logged-user/actions';
import { SITE_SERVER } from './misc';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true;
}

export const requestProducts = () => ( // eslint-disable-line
  makeRequest({
    uri: '/api/products',
    method: METHODS.GET,
    mockResponse: getPurchaseOrderData(),
    useAuthentication: false,
  })
);

export const postAddProduct = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/products${id !== null ? `/${id}` : ''}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

/* export const requestProducts = (limit, page, type, text, vendorId, sortColumn, sortType) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/products?limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${text !== null ? `&text=${text}` : ''}${vendorId !== null ? `&vendor_id=${vendorId}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: wmsheader(),
}); */

export const requestProductDetails = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/products/${id}`,
  headers: wmsheader(),
});

export const autoSearchProduct = (clientId, txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/products?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

/* export const requestProductTypes = () => ( // eslint-disable-line
  makeRequest({
    uri: '/api/producttypes',
    method: METHODS.GET,
    mockResponse: getProdTypes(),
    useAuthentication: false,
  })
); */

export const requestProdTypes = () => axios({
  method: 'GET',
  url: `${SITE_SERVER.replace('/api', '')}/storage/data/productTypes.json`,
  headers: wmsheader(),
});

export const postAddVendor = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/vendors`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const postAddVendorOrders = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/vendorders`,
  headers: wmsheader(),
  data: dataToSubmit,
});

/* export const postAddVendorOrders = (dataToSubmit, id) => axios({
  method: id !== null ? 'PUT' : 'POST',
  url: `${SITE_SERVER}/vendorders${id !== null ? `/${id}` : ''}`,
  headers: wmsheader(),
  data: dataToSubmit,
}); */

export const autoSearchVendor = (clientId, txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/vendors?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const autoSearchCarrier = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/carriers?limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const autoSearchPO = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/autocomplete/vendorders?limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const postAddManufacturer = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/manufacturers`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const autoSearchManufacturer = (txt, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/autocomplete/manufacturers?limit=${limit}&page=${page}&text=${txt}`,
  headers: wmsheader(),
});

export const importWMSProducts = () => axios({
  method: 'POST',
  url: `${SITE_SERVER}/wms_imports/products`,
  headers: wmsheader(),
});

export const lastImportProducts = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/wms_imports/products`,
  headers: wmsheader(),
});

export const requestProductSalesOrder = (id, limit, page, type, start, end) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/products/orders/${id}?limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${start !== null ? `&order_date_start=${start}` : ''}${end !== null ? `&order_date_end=${end}` : ''}`,
  headers: wmsheader(),
});

export const stockAlertListing = (limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/products/stock_alert?limit=${limit}&page=${page}`,
  headers: wmsheader(),
});

export const addProductStockAlert = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/products/stock_alert`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const updateProductStockAlert = (id, dataToSubmit) => axios({
  method: 'PUT',
  url: `${SITE_SERVER}/products/stock_alert/${id}`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const deleteProductStockAlert = id => axios({
  method: 'DELETE',
  url: `${SITE_SERVER}/products/stock_alert/${id}`,
  headers: wmsheader(),
});

export const stockAlertRecipients = () => axios({
  method: 'GET',
  url: `${SITE_SERVER}/stockalerts/recipient`,
  headers: wmsheader(),
});

export const addUpdateStockAlertRecipients = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/stockalerts/recipient`,
  headers: wmsheader(),
  data: {
    emails: dataToSubmit,
  },
});

export const deleteStockAlertRecipients = () => axios({
  method: 'DEL',
  url: `${SITE_SERVER}/stockalerts/recipient/anythinghere`,
  headers: wmsheader(),
});

export const requestSupplyReceipts = (clientId, limit, page, type, text, vendor, sortColumn, sortType) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/supplyreceipts?${clientId !== null && typeof clientId !== 'undefined' ? `&client_id=${clientId}&` : ''}limit=${limit}&page=${page}${type !== null ? `&type=${type}` : ''}${text !== null ? `&text=${text}` : ''}${vendor !== null ? `&vendor=${vendor}` : ''}${sortColumn !== null ? `&sort_column=${sortColumn}` : ''}${sortType !== null ? `&sort_type=${sortType}` : ''}`,
  headers: wmsheader(),
});

export const addSupplyReceipts = dataToSubmit => axios({
  method: 'POST',
  url: `${SITE_SERVER}/admin/supplyreceipts`,
  headers: wmsheader(),
  data: dataToSubmit,
});

export const requestClients = (status, limit, page) => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/clients?status=${status}&limit=${limit}&page=${page}`,
  headers: wmsheader(),
});

export const getSupplyReceiptInfo = id => axios({
  method: 'GET',
  url: `${SITE_SERVER}/admin/supplyreceipts/${id}`,
  headers: wmsheader(),
});
