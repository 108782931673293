const styles = theme => ({
  ...theme.classes,
  gridcontainer: {
    padding: '18px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridcaption: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.header.primary,
    marginBottom: 5,
  },
  gridbody: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  gridbody2: {
    color: `${theme.palette.text.primary} !important`,
  },
  gridbodygray: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#d9d7d7',
  },
  trow: {
    height: 'auto',
    border: '1px solid #d9d7d7',
    '& th': {
      color: theme.palette.primary.light,
      height: 38,
      fontSize: 12,
      fontWeight: 400,
      '@media (max-width:1240px)': {
        padding: 0,
      },
    },
    '& th.desc': {
      width: '56%',
      textAlign: 'left',
      '@media (max-width:1240px)': {
        display: 'none',
        '&.desc2': {
          display: 'table-cell',
        },
      },
    },
    '& th.skudesc': {
      '& span': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        '& span': {
          display: 'inline',
        },
      },
    },
    '& .tcol2': {
      paddingLeft: 20,
    },
    '& td': {
      height: 44,
      '& .description': {
        display: 'none',
      },
      '@media (max-width:1240px)': {
        padding: 0,
        paddingTop: 16,
        paddingBottom: 16,
        '&.desc': {
          display: 'none',
        },
        '& .description': {
          display: 'block',
          fontSize: 12,
          fontWeight: 400,
        },
      },
    },
    '& td p': {
      color: theme.palette.text.gray20,
      '@media (max-width:1240px)': {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    '& th:first-child': {
      borderTopLeftRadius: 6,
      '@media (max-width:1240px)': {
        borderTopLeftRadius: 0,
        paddingLeft: 30,
      },
    },
    '& th:last-child': {
      borderTopRightRadius: 6,
      '@media (max-width:1240px)': {
        borderTopRightRadius: 0,
        paddingRight: 30,
      },
    },
    '& td:first-child': {
      '@media (max-width:1240px)': {
        paddingLeft: 30,
      },
    },
    '& td:last-child': {
      '@media (max-width:1240px)': {
        paddingRight: 30,
      },
    },
  },
  h6: {
    fontSize: 14,
    fontWeight: 500,
  },
  alignRight: {
    textAlign: 'right',
  },
  table: {
    marginBottom: 20,
    position: 'relative',
    background: '#fff',
    width: '100%',
    borderCollapse: 'inherit',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  t1: {
    fontSize: 12,
    color: theme.palette.primary.light,
    marginBottom: 20,
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
      padding: '16px 30px 8px',
      margin: 0,
      color: theme.palette.common.black,
    },
  },
  thead: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 0px 6px 0px rgba(68, 107, 176, 0.91), 0px 10px 6px 0px rgba(68, 107, 176, 0.38)',
    borderRadius: 6,
    '@media (max-width:1240px)': {
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
  tbody: {
    position: 'relative',
    background: '#fff',
    width: '100%',
  },
  tblsortlbl: {
    whiteSpace: 'nowrap',
    '& svg': {
      width: 9,
      fill: '#446BB0',
      marginLeft: 6,
    },
  },
  tlabel: {
    margin: '48px 0 22px',
  },
});

export default styles;
