import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import GridTableMobileStyle from 'ui/components/common/wms/GridTableMobileStyle';
import styles from './DetailsStyle';

class ThirdPartyDetails extends Component {
  render() {
    const { classes, detail } = this.props;
    const data = detail.attributes;
    let showrecipient = true;
    if (data.order_info.recipient.recipient_name === null &&
        data.order_info.recipient.shipping_address1 === null &&
        data.order_info.recipient.shipping_city === null &&
        data.order_info.recipient.shipping_state === null &&
        data.order_info.recipient.shipping_zip === null &&
        data.order_info.recipient.shipping_country_code === null &&
        data.order_info.recipient.recipient_phone === null &&
        data.order_info.recipient.recipient_email === null
    ) {
      showrecipient = false;
    }
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.top}>
            { showrecipient ?
              <Grid className={classes.gridcontainer} container spacing={0}>
                <Grid className={classes.nameadd} item xs={6}>
                  <Typography className={classes.name}>
                    {data.order_info.recipient.recipient_name}
                  </Typography>
                  <Typography className={classes.address}>
                    {`${data.order_info.recipient.shipping_address1} \n`}
                    {data.order_info.recipient.shipping_city !== null && data.order_info.recipient.shipping_city !== '' ? `${data.order_info.recipient.shipping_city},` : ''}&nbsp;
                    {data.order_info.recipient.shipping_state !== null && data.order_info.recipient.shipping_state !== '' ? `${data.order_info.recipient.shipping_state},\n` : ''}
                    {data.order_info.recipient.shipping_zip !== null && data.order_info.recipient.shipping_zip !== '' ? `${data.order_info.recipient.shipping_zip},` : ''}&nbsp;
                    {data.order_info.recipient.shipping_country_code}
                  </Typography>
                </Grid>
                <Grid className={classes.phoneadd} item xs={6}>
                  <div className={classes.rightcontainer}>
                    { data.order_info.recipient.recipient_phone !== null && data.order_info.recipient.recipient_phone !== '' ?
                      <>
                        <Typography className={classes.gridcaption}>Phone:</Typography>
                        <Typography className={classNames(classes.gridbody, classes.gridbody2, classes.mb18)} variant="body1">{data.order_info.recipient.recipient_phone}</Typography>
                      </>
                    : null }
                  </div>
                  <div className={classes.rightcontainer}>
                    { data.order_info.recipient.recipient_email !== null && data.order_info.recipient.recipient_email !== '' ?
                      <>
                        <Typography className={classes.gridcaption}>Email:</Typography>
                        <Typography className={classNames(classes.gridbody, classes.gridbody2)} variant="body1">{data.order_info.recipient.recipient_email}</Typography>
                      </>
                    : null }
                  </div>
                </Grid>
              </Grid>
            : null }
          </div>
          <div className={classes.mid}>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.div100} item xs={5}>
                <Typography className={classes.gridcaption}>Order Reference</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_reference}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divleft}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">RA Number</Typography>
                { data.ra_number !== null && data.ra_number !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.ra_number}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`${classes.div50} ${classes.divright}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Reason for Return</Typography>
                { data.reason !== null && data.reason !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.reason}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={`${classes.div50} ${classes.divbottomleft}`} item xs={5}>
                <Typography className={classes.gridcaption} variant="caption">Customer Name</Typography>
                { data.order_info.recipient.recipient_name !== null && data.order_info.recipient.recipient_name !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_info.recipient.recipient_name}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`${classes.div50} ${classes.divbottomright}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">Tracking Number</Typography>
                { data.tracking_no !== null && data.tracking_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.tracking_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`${classes.div50} ${classes.divtopleft}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Return Notes</Typography>
                { data.notes !== null && data.notes !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.notes}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={`${classes.divider} ${classes.hideonmobile}`} />
          </div>
          { data.items !== null ?
            <>
              <Typography className={classes.t1}>List of Returned Items:</Typography>
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow className={classes.trow}>
                    <TableCell className="skudesc" align="left">SKU<span>/Description</span></TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Condition</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  { data.items.length ?
                    <>
                      { data.items.map(item => (
                        <TableRow className={classes.trow} key={item.sort_no}>
                          <TableCell align="left">
                            <Typography className={classes.medium} color="primary">{item.sku}</Typography>
                            <Typography className="description" color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography className={classes.medium} color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.medium} color="primary">{item.quantity}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.medium} color="primary">
                              {item.condition ? 'Damaged or Used' : 'Good Condition or Unused'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  :
                    <TableRow className={classes.trow}>
                      <TableCell align="center" colSpan="4">
                        <Typography className={classes.medium} color="primary">No results found.</Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </>
          : null }
        </div>
      </div>
    );
  }
}

ThirdPartyDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(theme => ({
  ...styles(theme),
  ...GridTableMobileStyle(theme),
}))(ThirdPartyDetails);

