const styles = theme => ({
  ...theme.classes,
  gridContainer: {
    margin: 0,
  },
  h2: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
      marginBottom: 20,
    },
  },
  col0: {
    padding: '10px 0 0',
  },
  col1: {
    padding: '10px 40px 0 0',
  },
  col2: {
    padding: '10px 20px 0',
  },
  col3: {
    padding: '10px 0 0 40px',
  },
  col1b: {
    padding: '10px 20px 0 0',
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 18px',
    },
  },
  cola1: {
    padding: '10px 27px 0 0',
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 18px',
    },
  },
  cola2: {
    padding: '10px 0 0 27px',
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 18px',
    },
  },
  col2b: {
    padding: '10px 0 0 20px',
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: 0,
    },
  },
  recipientform: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.paper,
      padding: 14,
    },
  },
  sc: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.border}`,
    padding: '26px 18px 8px',
    borderRadius: 5,
    marginBottom: 20,
    '&.freight': {
      padding: '26px 18px 26px',
    },
    '@media (max-width:1240px)': {
      borderRadius: 0,
      padding: '26px 18px 24px',
      margin: 0,
    },
  },
  sc2: {
    padding: '20px 24px 0',
  },
  sc3: {
    padding: '26px 18px 26px',
  },
  col1a: {
    padding: '0 12px 0 0',
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      '&.addprodinpt': {
        padding: '0 10px 0 0',
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
  },
  col2a: {
    padding: '0 6px 0',
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  col3a: {
    padding: '0 0 0 12px',
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '100%',
      flexBasis: '100%',
      '&.addprodinpt': {
        padding: '0 0 0 10px',
        maxWidth: '50%',
        flexBasis: '50%',
      },
    },
  },
  col11a: {
    padding: '0 4px 0 0',
  },
  col22a: {
    padding: '0 4px 0',
  },
  col33a: {
    padding: '0 0 0 4px',
  },
  lbl: {
    fontSize: 14,
    color: theme.palette.text.gray20,
    margin: '10px 0 15px',
  },
  gridboxcontainer: {
    position: 'relative',
    flexWrap: 'nowrap',
    backgroundColor: '#fff',
    border: '1px solid #d9d7d7',
    padding: 14,
    borderRadius: 5,
    marginBottom: 20,
  },
  gridcaption: {
    fontSize: 11,
    fontWeight: 400,
    lineHieght: '16px',
    color: theme.palette.header.primary,
  },
  gridbody: {
    fontSize: 14,
    fontWeight: 500,
    lineHieght: '16px',
    color: theme.palette.primary.main,
  },
  change: {
    position: 'absolute',
    top: 12,
    right: 12,
    fontSize: 11,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  actionbtnWrapper: {
    textAlign: 'right',
    marginBottom: 16,
  },
  outlinedbtn: {
    backgroundColor: '#fff',
  },
  actionbtn: {
    width: 88,
    display: 'inline-block',
    padding: 0,
    marginLeft: 12,
  },
  skuwrapper: {
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: 5,
    padding: '24px 26px 20px',
    margin: '20px 0 30px',
    '@media (max-width:1240px)': {
      borderRadius: 0,
      border: 'none',
      margin: 0,
      padding: 0,
    },
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    color: theme.palette.primary.main,
    marginBottom: 15,
    '& .error': {
      color: '#f44336',
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 30,
      verticalAlign: 'middle',
    },
    '@media (max-width:1240px)': {
      backgroundColor: '#C5D1E0',
      margin: 0,
      padding: '30px 18px 0',
      '&[data-totalitem="1"]': {
        backgroundColor: '#fff',
        borderBottom: `1px solid ${theme.palette.background.border}`,
        padding: '30px 18px 24px',
      },
    },
  },
  gh: {
    lineHeight: '16px',
    borderBottom: '1px solid #E8EAEE',
    paddingBottom: 10,
    marginBottom: 22,
    '& span': {
      fontSize: 13,
      lineHeight: '16px',
      color: theme.palette.primary.light,
    },
    '& .list': {
      paddingLeft: 18,
    },
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  gh2: {
    lineHeight: '16px',
    paddingBottom: 10,
    marginBottom: 22,
    '& span': {
      fontSize: 13,
      lineHeight: '16px',
      color: theme.palette.primary.light,
    },
    '& .list': {
      paddingLeft: 18,
    },
    '@media (max-width:1240px)': {
      '& .grp1, & .grp22': {
        display: 'none',
      },
      '& .grp2, & .list': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .list': {
        paddingLeft: 0,
      },
    },
  },
  skulist: {
    '& .list': {
      paddingLeft: 18,
    },
    '@media (max-width:1240px)': {
      padding: '36px 20px 32px',
      '& .list': {
        paddingLeft: 14,
        maxWidth: '100%',
      },
      '&[data-lastitem="false"]': {
        backgroundColor: '#C5D1E0',
        borderBottom: '1px solid #fff',
      },
      '&[data-lastitem="false"] span': {
        color: '#767F91',
      },
      '&[data-lastitem="false"] .autocomplete label': {
        display: 'none',
      },
      '&[data-lastitem="false"] .autocomplete fieldset': {
        top: 0,
      },
      '&[data-lastitem="false"] .autocomplete fieldset legend': {
        display: 'none',
      },
      '& .sq': {
        display: 'block',
      },
    },
  },
  ptitle: {
    fontSize: 14,
    color: theme.palette.common.black,
    fontWeight: 400,
    lineHeight: '16px',
    marginBottom: 18,
  },
  spacer: {
    width: '100%',
    height: 12,
    display: 'block',
  },
  statestyle: {
    '& .selectHolder label': {
      backgroundColor: '#fff',
      fontSize: 12,
      '@media (max-width:1240px)': {
        fontSize: 14,
      },
    },
  },
  statestyle2: {
    '& .inputHolder label': {
      fontSize: 12,
      '@media (max-width:1240px)': {
        fontSize: 14,
      },
    },
  },
  txtnotes: {
    width: '100%',
  },
  sublbl: {
    fontSize: 12,
    color: '#949DB2',
  },
  subucontainer: {
    padding: 0,
  },
  subuitemtext: {
    '& span': {
      fontSize: 12,
      color: '#5D5B5B',
    },
    '& p': {
      fontSize: 12,
    },
  },
});

export default styles;
