import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Dropdown } from 'resources/icons/dropdown.svg';
import { ReactComponent as Close } from 'resources/icons/closev2.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Grow, Paper, Popper, Typography, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './ColumnsStyle';

const menulist = [
  { key: 0, value: 'New' },
  { key: 1, value: 'Pending' },
  { key: 2, value: 'On Hold' },
  { key: 3, value: 'Cancelled' },
  { key: 4, value: 'Shipped' },
  { key: 5, value: 'Backordered' },
  { key: 6, value: 'Hold For Client Instructions' },
  { key: 7, value: 'Hold For Instructions' },
  { key: 8, value: 'Hold For Labels' },
  { key: 9, value: 'Hold Potential Fraud' },
  { key: 10, value: 'Invalid Address' },
];

class StatusList extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleStatus = (e) => {
    let closenow = false;
    const filterData = {
      id: 4,
      type: parseInt(e.currentTarget.dataset.id, 10),
      status: e.currentTarget.dataset.value,
    };
    this.addSearchFilter(filterData, 'status');
    closenow = true;
    if (closenow) {
      this.setState({ open: false });
    }
  };

  addSearchFilter = (filterData, elid) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          if (elid === 'status') {
            filterItems[key].status = filterData.status;
            filterItems[key].type = filterData.type;
          }
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
  }

  render() {
    const { classes/* , filtersdata */ } = this.props;
    const { open } = this.state;

    /* const status = filtersdata.filter(sel => sel.id === 4); */
    const statusType = '';
    /* if (status.length) {
      statusType = status[0].type;
    } */

    return (
      <div className={classes.statusbox}>
        <div className={classes.statuscolumn}>
          <IconButton
            className={classes.statusiconbtn}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            disableRipple
          >
            <span>Status</span> <Dropdown />
          </IconButton>
        </div>
        <Popper
          className={classes.statussortpopper}
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div>
                    <div className={classes.sortlisthead}>
                      <Typography>Status</Typography>
                      <Close onClick={this.handleClose} className={classes.closeX} />
                    </div>
                    <List className={classes.sortulistcontainer}>
                      {menulist.map(menu => (
                        <ListItem
                          key={menu.key}
                          data-id={menu.key}
                          data-value={menu.value}
                          onClick={this.handleStatus}
                          className={menu.key === statusType ? 'selected' : ''}
                        >
                          <ListItemText
                            className="itext"
                            primary={menu.value}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

StatusList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  setloadtype: PropTypes.func.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StatusList);
