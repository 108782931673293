const styles = theme => ({
  column: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  statuscolumn: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  iconbtn: {
    backgroundColor: theme.palette.common.white,
    height: 28,
    border: '1px solid #C5D1E0',
    borderRadius: 100,
    padding: '0 14px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    },
    '& span span': {
      fontSize: 12,
      marginRight: 10,
      '@media (max-width:370px)': {
        fontSize: 10,
        marginRight: 6,
      },
    },
  },
  sorticonbtn: {
    backgroundColor: theme.palette.common.white,
    height: 28,
    padding: '0 14px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
    },
    '& span span': {
      fontSize: 12,
      marginLeft: 10,
      color: theme.palette.primary.light,
      '@media (max-width:370px)': {
        fontSize: 10,
        marginLeft: 6,
      },
    },
  },
  statusbox: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
  },
  statusiconbtn: {
    backgroundColor: 'transparent',
    height: 52,
    padding: '0 18px',
    width: '100%',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& span span': {
      fontSize: 12,
      color: theme.palette.primary.light,
      '@media (max-width:370px)': {
        fontSize: 10,
        marginLeft: 6,
      },
    },
  },
  popper: {
    top: '6px !important',
    left: '-24px !important',
  },
  sortpopper: {
    top: '12px !important',
    left: '76px !important',
  },
  statussortpopper: {
    top: '1px !important',
    left: '54px !important',
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    width: 238,
    boxShadow: '0 0 12px rgba(68, 107, 176, 0.91)',
    borderRadius: 6,
  },
  ulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      padding: 0,
      borderTop: '1px solid #E8EAEE',
    },
    /* '& li:hover .listAction': {
      display: 'inline-block',
    }, */
    '& li > div': {
      padding: '12px 18px',
    },
    '& li > div:hover': {
      backgroundColor: 'transparent',
    },
    '& div': {
      padding: 0,
    },
    '& span': {
      color: '#2C2C2C',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 300,
      padding: 0,
    },
    '& div div span': {
      paddingLeft: 12,
      cursor: 'default',
    },
  },
  sortulistcontainer: {
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '100%',
    '& li': {
      padding: 0,
      borderTop: '1px solid #E8EAEE',
      cursor: 'pointer',
    },
    '& li:hover, & li.selected': {
      backgroundColor: theme.palette.primary.main,
    },
    '& li > div': {
      padding: '12px 18px !important',
    },
    '& li > div:hover': {
      backgroundColor: 'transparent',
    },
    '& div': {
      padding: 0,
    },
    '& span': {
      color: '#2C2C2C',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 300,
      padding: 0,
    },
    '& .itext span': {
      fontSize: '12px',
      fontWeight: 400,
      color: `${theme.palette.text.primary}`,
    },
    '& li:hover span, & li.selected span': {
      color: '#fff',
    },
    '& div div span': {
      cursor: 'default',
    },
  },
  listhead: {
    padding: '8px 18px',
    textAlign: 'left',
    '& p': {
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 500,
    },
  },
  sortlisthead: {
    padding: '8px 18px',
    textAlign: 'left',
    '& p': {
      fontSize: 11,
      lineHeight: '13px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },
  itext: {
    '& span': {
      fontSize: '12px',
      color: `${theme.palette.text.primary}`,
    },
  },
  actions: {
    display: 'none',
    padding: '0 4px 0 0 !important',
  },
  arrowiconbtn: {
    padding: 10,
    '& svg': {
      fill: '#949DB2',
    },
    '&[disabled] svg': {
      fill: '#D9D7D7',
    },
  },
  chkbox: {
    '& svg': {
      fill: '#707070',
    },
  },
  checked: {
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
  checkeddisabled: {
    '& svg': {
      fill: theme.palette.background.border,
    },
  },
  itextdisabled: {
    '& span': {
      fontSize: '12px !important',
      color: `${theme.palette.primary.light} !important`,
    },
  },
  closeX: {
    display: 'none',
    '@media (max-width:1240px)': {
      top: 5,
      right: 16,
      display: 'block',
      position: 'absolute',
      padding: 4,
      width: 20,
      height: 20,
    },
  },
  sortingbox: {
    position: 'relative',
    zIndex: 2,
  },
});

export default styles;
