import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import GridTableMobileStyle from 'ui/components/common/wms/GridTableMobileStyle';
import styles from './DetailsStyle';

class ReturnedOrderDetails extends Component {
  render() {
    const { classes, detail } = this.props;
    const data = detail.attributes;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.topRA}>
            {/* <div>
              <Typography
                className={`caption ${classes.gridcaption}`}
                variant="caption"
              >
                RA Number:
              </Typography>
              <Typography
                className={`${classes.gridbody} ${classes.medium}`}
                variant="body1"
              >
                201902152RAYB
              </Typography>
            </div>
            <div>
              <Typography
                className={`caption ${classes.gridcaption}`}
                variant="caption"
              >
                RA Date:
              </Typography>
              <Typography
                className={`${classes.gridbody} ${classes.medium}`}
                variant="body1"
              >
                1/23/2019
              </Typography>
            </div> */}
            <div>
              <Typography className={`caption ${classes.gridcaption}`} variant="caption">Submitted By:</Typography>
              { data.created_by_user !== null && data.created_by_user !== '' ?
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">
                  {data.created_by_user.firstname} {data.created_by_user.lastname}
                </Typography>
              :
                <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
              }
            </div>
            <div>
              <Typography className={`caption ${classes.gridcaption}`} variant="caption">Reason:</Typography>
              { data.reason !== null && data.reason !== '' ?
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.reason}</Typography>
              :
                <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
              }
            </div>
          </div>
          <div className={classes.top}>
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.nameadd} item xs={6}>
                <Typography className={classes.name}>
                  {data.order_info.recipient.recipient_name}
                </Typography>
                <Typography className={classes.address}>
                  {data.order_info.recipient.shipping_address1}<br />
                  {data.order_info.recipient.shipping_city},&nbsp;
                  {data.order_info.recipient.shipping_state},<br />
                  {data.order_info.recipient.shipping_zip},&nbsp;
                  {data.order_info.recipient.shipping_country_code}
                </Typography>
              </Grid>
              <Grid className={classes.phoneadd} item xs={6}>
                <div className={classes.rightcontainer}>
                  <Typography className={classes.gridcaption}>Phone:</Typography>
                  <Typography
                    className={classNames(classes.gridbody, classes.gridbody2, classes.mb18)}
                    variant="body1"
                  >
                    {data.order_info.recipient.recipient_phone}
                  </Typography>
                </div>
                <div className={classes.rightcontainer}>
                  <Typography className={classes.gridcaption}>Email:</Typography>
                  { data.order_info.recipient.recipient_email !== null && data.order_info.recipient.recipient_email !== '' ?
                    <Typography className={classNames(classes.gridbody, classes.gridbody2)} variant="body1">{data.order_info.recipient.recipient_email}</Typography>
                  :
                    <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.mid}>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.div100} item xs={5}>
                <Typography className={classes.gridcaption}>Order ID</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_id}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divleft}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">Order Date</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.created_at2}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divright}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Status</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium, classes.txtRC)} variant="body1" data-status={data.status ? 'Received' : 'Awaiting Return'}>
                  {data.status ? 'Received' : 'Awaiting Return'}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={`${classes.gridcontainer} ${classes.hideonmobile}`} container spacing={0}>
              {/* <Grid item xs={5}>
                <Typography className={classes.gridcaption} variant="caption">RA Number</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)}
                variant="body1">{data.RANUMBER}</Typography>
              </Grid> */}
              <Grid className={classes.hideonmobile} item xs={5}>
                <Typography className={classes.gridcaption} variant="caption">Reason</Typography>
                { data.reason !== null && data.reason !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.reason}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={classes.hideonmobile} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">Submitted By</Typography>
                { data.created_by_user !== null && data.created_by_user !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">
                    {data.created_by_user.firstname} {data.created_by_user.lastname}
                  </Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={classes.hideonmobile} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">Notes</Typography>
                { data.notes !== null && data.notes !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.notes}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.gridcontainer} container spacing={0}>
              <Grid className={classes.hideonmobile} item xs={5}>
                <Typography className={classes.gridcaption} variant="caption">Carrier Code</Typography>
                <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_info.carrier_code}</Typography>
              </Grid>
              <Grid className={`${classes.div50} ${classes.divbottomleft}`} item xs={4}>
                <Typography className={classes.gridcaption} variant="caption">3rd Party Billing</Typography>
                { data.order_info.thirdparty_billing_acct_no !== null && data.order_info.thirdparty_billing_acct_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_info.thirdparty_billing_acct_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
              <Grid className={`${classes.div50} ${classes.divbottomright}`} item xs={3}>
                <Typography className={classes.gridcaption} variant="caption">PO Number</Typography>
                { data.order_info.po_no !== null && data.order_info.po_no !== '' ?
                  <Typography className={classNames(classes.gridbody, classes.medium)} variant="body1">{data.order_info.po_no}</Typography>
                :
                  <Typography className={classes.gridbodygray} variant="body1">_____________</Typography>
                }
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </div>
          { data.items !== null ?
            <>
              <Typography className={classes.t1}>Items Ordered:</Typography>
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow className={classes.trow}>
                    <TableCell className="skudesc" align="left">SKU<span>/Description</span></TableCell>
                    <TableCell className="desc">Description</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  { typeof data.items !== 'undefined' && data.items.length ?
                    <>
                      { data.items.map(item => (
                        <TableRow className={classes.trow} key={item.sort_no}>
                          <TableCell align="left">
                            <Typography className={classes.medium} color="primary">{item.sku}</Typography>
                            <Typography className="description" color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell className="desc" align="left">
                            <Typography className={classes.medium} color="primary">{item.description}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.medium} color="primary">{item.quantity}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  :
                    <TableRow className={classes.trow}>
                      <TableCell align="center" colSpan="3">
                        <Typography className={classes.medium} color="primary">No results found.</Typography>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </>
          : null }
        </div>
      </div>
    );
  }
}

ReturnedOrderDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(theme => ({
  ...styles(theme),
  ...GridTableMobileStyle(theme),
}))(ReturnedOrderDetails);

