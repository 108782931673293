import { createSelector } from 'reselect';

export const eventsSelector = state => state.settings.calevents;
export const eventSelector = state => state.settings.singleevent;
export const clientSelector = state => state.settings.clientsObj;
export const clientdetailsSelector = state => state.settings.clientdetails;
export const userSelector = state => state.settings.usersObj;

export const getEvents = createSelector(
  eventsSelector,
  calevents => calevents,
);

export const getSingleEvent = createSelector(
  eventSelector,
  singleevent => singleevent,
);

export const getClients = createSelector(
  clientSelector,
  clientsObj => clientsObj,
);

export const getClientDetails = createSelector(
  clientdetailsSelector,
  clientdetails => clientdetails,
);

export const getUsers = createSelector(
  userSelector,
  usersObj => usersObj,
);
