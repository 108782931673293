const styles = theme => ({
  ...theme.classes,
  mainwrapper: {
    marginTop: -42,
    paddingTop: 6,
    '@media (max-width:1240px)': {
      marginTop: -46,
      paddingTop: 0,
    },
  },
  mobileselect: {
    display: 'none',
    '@media (max-width:1240px)': {
      padding: '20px 24px 20px',
      display: 'block',
      '& button': {
        color: theme.palette.secondary.main,
      },
    },
  },
  container: {
    background: '#fff',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.10)',
    position: 'relative',
    width: '100%',
    padding: 0,
    borderRadius: 8,
    minHeight: '85vh',
    '@media (max-width:1240px)': {
      borderRadius: 0,
      padding: 0,
      minHeight: '82vh',
    },
  },
  customcontainer: {
    padding: 0,
    minHeight: '85vh',
    marginBottom: 20,
    '@media (max-width:1240px)': {
      '&.faqcontainer': {
        minHeight: '100vh',
        marginBottom: 0,
      },
    },
  },
  headercontainer: {
    backgroundColor: theme.palette.secondary.main,
    height: 50,
    width: '100%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    boxShadow: '0 0 6px rgba(175, 175, 175, 0.91)',
    padding: '0 44px',
    alignItems: 'center',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  h1: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
    color: '#fff',
  },
  ulconatiner: {
    padding: '0 44px 40px',
    '@media (max-width:1240px)': {
      padding: '0 24px',
    },
  },
  liheadcontainer: {
    marginTop: 46,
    borderBottom: '1px solid #E4E4E4',
    paddingBottom: 6,
    alignItems: 'flex-end',
    '& .th label, &.clientth label': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
      color: theme.palette.primary.light,
    },
    '& .last': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    '@media (max-width:1240px)': {
      marginTop: 26,
      '& .g1': {
        maxWidth: '32%',
        flexBasis: '32%',
      },
      '& .g2': {
        maxWidth: '30%',
        flexBasis: '30%',
      },
      '& .g3': {
        maxWidth: '38%',
        flexBasis: '38%',
      },
      '& .first': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .last': {
        display: 'none',
      },
    },
  },
  licontainer: {
    height: 80,
    borderBottom: '1px solid #E4E4E4',
    alignItems: 'center',
    '& label': {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.main,
      '@media (max-width:1240px)': {
        fontSize: 14,
      },
    },
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      color: '#339B40',
    },
    '& .td span': {
      fontSize: 14,
      fontWeight: 400,
      color: '#383838',
    },
    '& span.inactive': {
      color: '#949DB2',
    },
    '@media (max-width:1240px)': {
      '& .g1': {
        maxWidth: '32%',
        flexBasis: '32%',
      },
      '& .g2': {
        maxWidth: '30%',
        flexBasis: '30%',
        '& span': {
          fontSize: 12,
        },
      },
      '& .g3': {
        maxWidth: '38%',
        flexBasis: '38%',
      },
      '& .first': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .last': {
        display: 'none',
      },
    },
  },
  btnwrapper: {
    textAlign: 'right',
    '& .btnbox': {
      display: 'inline-block',
      position: 'relative',
    },
    '@media (max-width:1240px)': {
      position: 'absolute',
      top: -46,
      right: 19,
    },
  },
  addbtn: {
    backgroundColor: theme.palette.secondary.main,
    width: 130,
    height: 30,
    borderRadius: 4,
    textTransform: 'capitalize',
    '& span, & label': {
      color: '#fff',
      fontSize: 12,
      fontWeight: 400,
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.btnmobile': {
      display: 'none',
    },
    '@media (max-width:1240px)': {
      width: 100,
      '& span': {
        fontSize: 11,
      },
      '&.btnmobile': {
        position: 'absolute',
        top: -45,
        right: 20,
        display: 'block',
        width: 124,
        height: 28,
        borderRadius: 100,
        padding: '3px 0',
        '&.adduser': {
          width: 118,
        },
        '& > span': {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0 17px',
        },
        '& span svg': {
          display: 'inline-block',
          stroke: '#fff',
          width: 14,
          height: 14,
        },
        '& span label': {
          display: 'inline-block',
          fontSize: 14,
          color: '#fff',
        },
      },
    },
  },
  editbtn: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: 56,
    height: 20,
    borderRadius: 4,
    textTransform: 'capitalize',
    marginRight: 14,
    '& span': {
      color: '#fff',
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '@media (max-width:1240px)': {
      marginRight: 4,
      '& span': {
        fontSize: 11,
      },
    },
  },
  delbtn: {
    backgroundColor: '#FF6565',
    minWidth: 56,
    height: 20,
    borderRadius: 4,
    textTransform: 'capitalize',
    '& span': {
      color: '#fff',
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: '#FF6565',
    },
    '@media (max-width:1240px)': {
      '& span': {
        fontSize: 11,
      },
    },
  },
  statusbtn: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: 76,
    height: 20,
    lineHeight: '16px',
    borderRadius: 4,
    textTransform: 'capitalize',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.deactivate': {
      backgroundColor: '#C73737',
      '&:hover': {
        backgroundColor: '#C73737',
      },
    },
    '& span': {
      color: '#fff',
      fontSize: 12,
    },
    '@media (max-width:1240px)': {
      marginRight: 4,
      '& span': {
        fontSize: 11,
      },
    },
  },
  desktopview: {
    display: 'block',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  mobileview: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  mobilecontainer: {
    background: '#fff',
    position: 'relative',
    width: '100%',
    borderRadius: 8,
    minHeight: '85vh',
    padding: '0 0 20px',
    '& .header': {
      height: 55,
      display: 'flex',
      alignItems: 'center',
      padding: '0 24px',
      borderBottom: '1px solid #E8EAEE',
      '& label': {
        fontSize: 12,
        color: '#949DB2',
      },
      '& .right': {
        textAlign: 'right',
      },
    },
    '& .list': {
      height: 108,
      alignItems: 'center',
      padding: '0 24px',
      borderBottom: '1px solid #E8EAEE',
      '& label': {
        color: '#446BB0',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 4,
      },
      '& p': {
        color: '#282C2E',
        fontSize: 12,
        fontWeight: 400,
        overflowWrap: 'break-word',
      },
      '& .right': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '& span.status': {
        fontSize: 12,
        fontWeight: 400,
        color: '#339B40',
        marginBottom: 8,
        textAlign: 'right',
      },
      '& span.status.inactive': {
        color: '#949DB2',
      },
      '& span.date': {
        fontSize: 12,
        fontWeight: 400,
        color: '#949DB2',
        marginBottom: 8,
        textAlign: 'right',
      },
      '& button': {
        margin: 0,
      },
      '& button.edit': {
        marginRight: 6,
      },
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 80,
  },
  actionbtns: {
    position: 'relative',
    width: 130,
    '& .editbtn': {
      position: 'absolute',
      top: -12,
      right: -6,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    '& .editpassbtn': {
      position: 'absolute',
      top: -14,
      right: -62,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  actionbtnsmobile: {
    position: 'relative',
    '& .editbtn': {
      position: 'absolute',
      top: -6,
      left: -40,
      padding: 6,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    '& .editpassbtn': {
      position: 'absolute',
      top: -10,
      left: -80,
      padding: 6,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
});

export default styles;
