const styles = theme => ({
  ...theme.classes,
  root: {
    display: 'flex',
  },
  container: {
    padding: '0 2px 0 64px',
    height: 'calc(100% - 76px)',
    '@media (max-width:1240px)': {
      padding: 0,
      height: 'calc(100% - 60px)',
    },
  },
  topRA: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
      backgroundColor: '#C5D1E0',
      padding: '24px 26px 12px',
      '& div': {
        marginBottom: 12,
      },
      '& p, & span': {
        display: 'inline-block',
        color: '#292929',
        fontSize: 14,
        fontWeight: 400,
      },
      '& .caption': {
        width: 100,
        opacity: 0.50,
        marginRight: 2,
      },
    },
  },
  top: {
    '@media (max-width:1240px)': {
      padding: '16px 30px 0',
      '& > div': {
        padding: 0,
      },
    },
  },
  content: {
    'overflow-y': 'auto',
    padding: '12px 52px 0 6px',
    height: '100%',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
      borderRadius: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar': {
      width: 10,
      backgroundColor: '#FEFEFE',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.2)',
      backgroundColor: '#F3F3F3',
    },
    '@media (max-width:1240px)': {
      padding: 0,
    },
  },
  gridboxcontainer: {
    padding: '18px 0',
    flexWrap: 'nowrap',
  },
  nameadd: {
    padding: '0 200px 0 0',
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  phoneadd: {
    '@media (max-width:1240px)': {
      padding: '16px 0 0 0',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  rightcontainer: {
    '@media (max-width:1240px)': {
      '& p': {
        display: 'inline-block',
        paddingRight: 12,
      },
    },
  },
  gridcaption2: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.header.primary,
  },
  gridbody2: {
    color: '#5D5B5B',
  },
  mb18: {
    marginBottom: 18,
    '@media (max-width:1240px)': {
      marginBottom: 8,
    },
  },
  name: {
    fontSize: 16,
    textTransform: 'capitalize',
    lineHeight: '19px',
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: 8,
    '@media (max-width:1240px)': {
      lineHeight: '22px',
      fontWeight: 400,
    },
  },
  address: {
    '@media (max-width:1240px)': {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  mid: {
    margin: '20px 0 50px',
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      margin: '20px 0 0',
    },
  },
  status: {
    textTransform: 'capitalize',
    '&[data-status="new"]': {
      color: '#339B40',
    },
    '&[data-status="pending"]': {
      color: '#B557FB',
    },
    '&[data-status="shipped"]': {
      color: '#99CE43',
    },
    '&[data-status="cancelled"]': {
      color: '#949DB2',
    },
    '&[data-status="on hold"]': {
      color: '#E3C000',
    },
    '&[data-status="backordered"]': {
      color: '#FF6565',
    },
  },
  div100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 30px 16px',
      borderBottom: '1px solid #E8EAEE',
    },
  },
  div100bottom: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '16px 30px 0',
      borderTop: '1px solid #E8EAEE',
    },
  },
  div50: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
  divleft: {
    '@media (max-width:1240px)': {
      padding: '16px 0 0 30px',
    },
  },
  divright: {
    '@media (max-width:1240px)': {
      padding: '16px 30px 0 20px',
      overflowWrap: 'break-word',
    },
  },
  divbottomleft: {
    '@media (max-width:1240px)': {
      padding: '0 0 16px 30px',
    },
  },
  divbottomright: {
    '@media (max-width:1240px)': {
      padding: '0 30px 16px 20px',
    },
  },
  divtopleft: {
    '@media (max-width:1240px)': {
      padding: '16px 0 0 30px',
      borderTop: '1px solid #E8EAEE',
    },
  },
  divtopright: {
    '@media (max-width:1240px)': {
      padding: '16px 30px 0 20px',
      borderTop: '1px solid #E8EAEE',
      overflowWrap: 'break-word',
    },
  },
  divleftcenter: {
    '@media (max-width:1240px)': {
      padding: '0 0 0 30px',
    },
  },
  divrightcenter: {
    '@media (max-width:1240px)': {
      padding: '0 30px 0 20px',
    },
  },
  divider: {
    '@media (max-width:1240px)': {
      backgroundColor: '#E8EAEE',
    },
  },
  hideonmobile: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  hideondesktop: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'flex',
      '&.inlineblock': {
        display: 'inline-block',
      },
    },
  },
  txtRC: {
    '&[data-status="Received"]': {
      color: '#B557FB',
    },
    '&[data-status="Awaiting Return"]': {
      color: '#339B40',
    },
  },
  uinfocontainer: {
    position: 'relative',
    '& .vinfolbl': {
      display: 'none',
    },
    '& .more': {
      display: 'none',
    },
    '@media (max-width:1240px)': {
      '& .vinfolbl': {
        display: 'block',
      },
      '&[data-expand="false"] .expansionPanel': {
        position: 'relative',
      },
      '&[data-expand="false"] .expansionDetails': {
        display: 'none',
      },
      '& .more': {
        position: 'absolute',
        top: 10,
        right: 0,
        left: 'inherit',
        display: 'block',
      },
    },
  },
  trackno: {
    color: `${theme.palette.secondary.main} !important`,
  },
});

export default styles;
