/* eslint-disable no-useless-escape */
var purchaseorder = {
  data: [{
    type: 'product',
    id: '165',
    attributes: {
      id: 254,
      client_id: 3,
      sku: '165',
      description: ' Set of 3 handles - Orange',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '0402',
      product_type_id: 0,
      unit_size_length: '0.10',
      unit_size_width: '0.10',
      unit_size_height: '0.10',
      weight: '0.30',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 254,
        quantity: '453',
        sold_quantity: '23',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '170',
    attributes: {
      id: 250,
      client_id: 3,
      sku: '170',
      description: 'Absolute Hot Yoga (DVD)',
      vendor_id: 15,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '8524',
      product_type_id: 0,
      unit_size_length: '5.00',
      unit_size_width: '7.00',
      unit_size_height: '2.00',
      weight: '0.50',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 250,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 15,
        client_id: 3,
        company_name: 'Absolute Yoga',
        name: 'Absolute Yoga',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:28',
        updated_at: null
      }
    }
  }, {
    type: 'product',
    id: '124',
    attributes: {
      id: 297,
      client_id: 3,
      sku: '124',
      description: 'Absolute Hot Yoga DVD',
      vendor_id: 5,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '99022335',
      product_type_id: 0,
      unit_size_length: '6.00',
      unit_size_width: '4.00',
      unit_size_height: '2.00',
      weight: '0.25',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '3.50',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:29',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 297,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 5,
        client_id: 3,
        company_name: 'YogaBody',
        name: 'YOGABODY',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '270',
    attributes: {
      id: 232,
      client_id: 3,
      sku: '270',
      description: 'Absolute Yoga 2013 Hot Yoga DVD-US (NTSC)',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '123456',
      product_type_id: 0,
      unit_size_length: '0.10',
      unit_size_width: '0.10',
      unit_size_height: '0.10',
      weight: '0.10',
      sales_price: '0.10',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:27',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 232,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '125',
    attributes: {
      id: 296,
      client_id: 3,
      sku: '125',
      description: 'Ashtanga Pose Chart',
      vendor_id: 5,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '4806.30.00',
      product_type_id: 0,
      unit_size_length: '8.50',
      unit_size_width: '11.00',
      unit_size_height: '0.10',
      weight: '0.10',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.50',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:29',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 296,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 5,
        client_id: 3,
        company_name: 'YogaBody',
        name: 'YOGABODY',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '167',
    attributes: {
      id: 252,
      client_id: 3,
      sku: '167',
      description: 'Audio USB',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '0403',
      product_type_id: 0,
      unit_size_length: '0.30',
      unit_size_width: '0.50',
      unit_size_height: '0.90',
      weight: '0.90',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 252,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '301',
    attributes: {
      id: 226,
      client_id: 3,
      sku: '301',
      description: 'Awesome Toes',
      vendor_id: 9,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '3926909090',
      product_type_id: 0,
      unit_size_length: '6.60',
      unit_size_width: '2.80',
      unit_size_height: '2.20',
      weight: '0.60',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.20',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:27',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 226,
        quantity: '20,110',
        sold_quantity: '54,990',
        returned_quantity: '0'
      },
      vendor: {
        id: 9,
        client_id: 3,
        company_name: 'YOGABODY Naturals LLC',
        name: 'YOGABODY Naturals LLC',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:22'
      }
    }
  }, {
    type: 'product',
    id: '303',
    attributes: {
      id: 225,
      client_id: 3,
      sku: '303',
      description: 'Awesome Toes - Large Size',
      vendor_id: 9,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '3926909090',
      product_type_id: 0,
      unit_size_length: '4.80',
      unit_size_width: '1.10',
      unit_size_height: '1.26',
      weight: '0.13',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.30',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:27',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 225,
        quantity: '-5',
        sold_quantity: '246',
        returned_quantity: '0'
      },
      vendor: {
        id: 9,
        client_id: 3,
        company_name: 'YOGABODY Naturals LLC',
        name: 'YOGABODY Naturals LLC',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:22'
      }
    }
  }, {
    type: 'product',
    id: '505',
    attributes: {
      id: 6,
      client_id: 3,
      sku: '505',
      description: 'Bamboo Handstand Block',
      vendor_id: 11,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '3918.90.9000',
      product_type_id: 0,
      unit_size_length: '7.60',
      unit_size_width: '5.50',
      unit_size_height: '4.00',
      weight: '3.60',
      sales_price: '0.00',
      upc: '612520789398',
      ean: '0612520789398',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '6.50',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-07-15 23:50:06',
      created_at_format1: '7\/15\/2019 | 23:50',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 6,
        quantity: '0',
        sold_quantity: '84',
        returned_quantity: '0'
      },
      vendor: {
        id: 11,
        client_id: 3,
        company_name: 'Suki Glasses',
        name: 'Suki Glasses',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: null
      }
    }
  }, {
    type: 'product',
    id: '554',
    attributes: {
      id: 183,
      client_id: 3,
      sku: '554',
      description: 'Birch Parallettes',
      vendor_id: 5,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '9506.91.0030',
      product_type_id: 0,
      unit_size_length: '15.70',
      unit_size_width: '7.10',
      unit_size_height: '2.70',
      weight: '4.52',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '20.33',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:26',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:22',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 183,
        quantity: '1',
        sold_quantity: '305',
        returned_quantity: '0'
      },
      vendor: {
        id: 5,
        client_id: 3,
        company_name: 'YogaBody',
        name: 'YOGABODY',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '3061',
    attributes: {
      id: 204,
      client_id: 3,
      sku: '3061',
      description: 'Bolts- Ceiling Hooks',
      vendor_id: 9,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '95069190',
      product_type_id: 0,
      unit_size_length: '3.40',
      unit_size_width: '0.50',
      unit_size_height: '0.50',
      weight: '0.50',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.00',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:27',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 204,
        quantity: '277',
        sold_quantity: '8',
        returned_quantity: '0'
      },
      vendor: {
        id: 9,
        client_id: 3,
        company_name: 'YOGABODY Naturals LLC',
        name: 'YOGABODY Naturals LLC',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:22'
      }
    }
  }, {
    type: 'product',
    id: '132',
    attributes: {
      id: 288,
      client_id: 3,
      sku: '132',
      description: 'Breathe Into Stillness CD',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '123456',
      product_type_id: 0,
      unit_size_length: '7.00',
      unit_size_width: '5.00',
      unit_size_height: '1.00',
      weight: '0.20',
      sales_price: '1.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.00',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 288,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '175',
    attributes: {
      id: 246,
      client_id: 3,
      sku: '175',
      description: "Briohny & Dice Yoga \u2013 Fit, Flow, Fly! (DVD)",
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '391000',
      product_type_id: 0,
      unit_size_length: '8.00',
      unit_size_width: '5.00',
      unit_size_height: '11.00',
      weight: '0.30',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 246,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '526780',
    attributes: {
      id: 321,
      client_id: 3,
      sku: '526780',
      description: 'Brochure',
      vendor_id: 14,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '0000',
      product_type_id: 0,
      unit_size_length: '5.00',
      unit_size_width: '6.00',
      unit_size_height: '0.01',
      weight: '0.01',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '350.00',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:29',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:25',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 321,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 14,
        client_id: 3,
        company_name: 'LA Printing',
        name: 'LA Printing',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: null
      }
    }
  }, {
    type: 'product',
    id: '118',
    attributes: {
      id: 302,
      client_id: 3,
      sku: '118',
      description: 'Business Card Holder',
      vendor_id: 5,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '4202.32.2000',
      product_type_id: 0,
      unit_size_length: '1.75',
      unit_size_width: '2.50',
      unit_size_height: '3.50',
      weight: '0.05',
      sales_price: '1.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.00',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:29',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:25',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 302,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 5,
        client_id: 3,
        company_name: 'YogaBody',
        name: 'YOGABODY',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '306',
    attributes: {
      id: 221,
      client_id: 3,
      sku: '306',
      description: 'Ceiling Hooks',
      vendor_id: 9,
      manufacturer_id: 0,
      origin_country_code: 'CN',
      tariff_code: '95069190',
      product_type_id: 0,
      unit_size_length: '10.30',
      unit_size_width: '5.10',
      unit_size_height: '2.60',
      weight: '3.60',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '1.20',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:27',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:23',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 221,
        quantity: '17,139',
        sold_quantity: '19,582',
        returned_quantity: '0'
      },
      vendor: {
        id: 9,
        client_id: 3,
        company_name: 'YOGABODY Naturals LLC',
        name: 'YOGABODY Naturals LLC',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:22'
      }
    }
  }, {
    type: 'product',
    id: '171',
    attributes: {
      id: 249,
      client_id: 3,
      sku: '171',
      description: 'Charity Bracelets',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '391000',
      product_type_id: 0,
      unit_size_length: '0.10',
      unit_size_width: '0.10',
      unit_size_height: '0.10',
      weight: '0.10',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 249,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '174',
    attributes: {
      id: 247,
      client_id: 3,
      sku: '174',
      description: 'Charity Bracelets small',
      vendor_id: 12,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '391000',
      product_type_id: 0,
      unit_size_length: '0.10',
      unit_size_width: '0.10',
      unit_size_height: '0.10',
      weight: '0.10',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '0.10',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:28',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:24',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 247,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 12,
        client_id: 3,
        company_name: 'Yogabody Naturals',
        name: 'YOGABODY Naturals',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:27',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }, {
    type: 'product',
    id: '110',
    attributes: {
      id: 310,
      client_id: 3,
      sku: '110',
      description: 'Chia Seed (Organic - 1 lbs. bag)',
      vendor_id: 5,
      manufacturer_id: 0,
      origin_country_code: 'US',
      tariff_code: '1209.99.4080',
      product_type_id: 0,
      unit_size_length: '9.00',
      unit_size_width: '6.00',
      unit_size_height: '2.00',
      weight: '1.00',
      sales_price: '0.00',
      upc: '',
      ean: '',
      isbn: '',
      vendor_product_no: '',
      is_pack: 'N',
      has_uuid: null,
      customs_value: '4.00',
      created_by: 0,
      updated_by: 0,
      created_at: '2019-08-08 05:49:29',
      created_at_format1: '8\/8\/2019 | 5:49',
      updated_at: '2019-08-14 22:11:25',
      updated_at_format1: '8\/14\/2019 | 22:11',
      inventory_summary: {
        client_id: 3,
        product_id: 310,
        quantity: '0',
        sold_quantity: '0',
        returned_quantity: '0'
      },
      vendor: {
        id: 5,
        client_id: 3,
        company_name: 'YogaBody',
        name: 'YOGABODY',
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country_code: 'US',
        status: 'A',
        meta: null,
        created_by: 1,
        updated_by: 0,
        created_at: '2019-08-08 05:49:26',
        updated_at: '2019-08-14 22:11:23'
      }
    }
  }]
}; // eslint-disable-next-line

export var getPurchaseOrderData = function getPurchaseOrderData() {
  return purchaseorder;
};