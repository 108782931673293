import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PageLoader from 'ui/components/loader/PageLoader';
import DetailsHeader from './DetailsHeader';
import Details from './Details';
import ReturnedOrderDetails from './ReturnedOrderDetails';
import ThirdPartyDetails from './ThirdPartyDetails';

const styles = theme => ({
  ...theme.classes,
});

class OrderDetailContainer extends Component {
  componentDidUpdate() {
    const {
      getloadtype, drawer, orderDetail, thirdPartyDetail, ordeItems, setloadtype,
    } = this.props;
    if (getloadtype === 'orders drawer') {
      setloadtype('order detail');
      if (drawer.showDrawer) {
        orderDetail(drawer.params.id);
      }
    }
    if (getloadtype === 'returned orders drawer') {
      setloadtype('order detail');
      if (drawer.showDrawer) {
        ordeItems(drawer.params.id);
      }
    }
    if (getloadtype === 'third party drawer') {
      setloadtype('order detail');
      if (drawer.showDrawer) {
        thirdPartyDetail(drawer.params.id);
      }
    }
  }

  render() {
    const {
      classes, actionloading, getloadtype, detail,
    } = this.props;
    if (getloadtype === 'orders drawer') {
      return (
        <div className={classes.drawerLoadingWrapper}>
          <PageLoader />
        </div>
      );
    }
    if (actionloading && getloadtype === 'order detail') {
      return (
        <div className={classes.drawerLoadingWrapper}>
          <PageLoader />
        </div>
      );
    }
    if (Object.keys(detail).length === 0) {
      return (
        <div className={classes.drawerLoadingWrapper}>
          <PageLoader />
        </div>
      );
    }
    return (
      <Fragment>
        <DetailsHeader {...this.props} />
        { detail.type === 'return_orders' ?
          <ReturnedOrderDetails {...this.props} />
        :
          <>
            { detail.type === 'thirdparty_return_orders' ?
              <ThirdPartyDetails {...this.props} />
            :
              <Details {...this.props} />
            }
          </>
        }
      </Fragment>
    );
  }
}

OrderDetailContainer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actionloading: PropTypes.bool.isRequired,
  getloadtype: PropTypes.string.isRequired,
  drawer: PropTypes.instanceOf(Object).isRequired,
  detail: PropTypes.instanceOf(Object).isRequired,
  orderDetail: PropTypes.func.isRequired,
  thirdPartyDetail: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
};

export default withStyles(styles)(OrderDetailContainer);
