import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'ui/components/form/FormField';
import { update, setFieldValue, populateOptionFields, resetOptionFields } from 'ui/components/form/FormActions';
import styles from './OrderFilterFormStyle';

const WAIT_INTERVAL = 500;

class MoreOrderFilterForm extends Component {
  state = {
    recipientvalue: '',
    formdata: {
      recipient_name: {
        element: 'autocomplete',
        value: '',
        config: {
          name: 'recipient_name',
          type: 'number',
          label: 'Recipient Name',
          options: [],
          editing: false,
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
        addNewClicked: (addNew, value) => this.addNewClicked(addNew, value),
        onChange: value => this.onRecipientChange(value),
        hasfunction: true,
        loadstatus: false,
        loadbtnstatus: false,
      },
      carrier_code: {
        element: 'select',
        value: '',
        config: {
          label: 'Carrier Code',
          name: 'carrier_code',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
      },
      company_name: {
        element: 'input',
        value: '',
        config: {
          label: 'Company Name',
          name: 'company_name',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      recipient_email: {
        element: 'input',
        value: '',
        config: {
          name: 'recipient_email',
          type: 'email',
          label: 'Email',
        },
        validation: {
          required: false,
          email: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
      },
    },
  }

  componentWillMount() {
    /* const {
      getCarrier, carrierdata, setloadtype,
    } = this.props;
    if (!carrierdata.length) {
      setloadtype('carrier');
      getCarrier();
    } */
    const { filtersdata } = this.props;
    const data = filtersdata.filter(sel => sel.id === 8);
    if (data.length) {
      const list = [{ _id: data[0].recipient_value, name: data[0].recipient_name }];
      const newFormdata = populateOptionFields(this.state.formdata, list, 'recipient_name');
      newFormdata.recipient_name.loadstatus = false;
      newFormdata.recipient_name.value = data[0].recipient_value;
      newFormdata.recipient_name.valid = true;
      newFormdata.recipient_name.touched = true;
      newFormdata.recipient_name.config.editing = true;

      newFormdata.company_name.value = data[0].company_name;
      newFormdata.recipient_email.value = data[0].recipient_email;
      this.updateFields(newFormdata);
    }
    this.timer = null;
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, autoRecipient, /* carrierdata, */
    } = this.props;
    if (!actionloading && getloadtype === 'auto vendor') {
      setloadtype('');
      if (autoRecipient.length) {
        const list = [];
        for (const key in autoRecipient) { // eslint-disable-line
          list.push({ _id: autoRecipient[key].attributes.id, name: autoRecipient[key].attributes.recipient_name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, list, 'recipient_name');
        newFormData.recipient_name.loadstatus = false;
        this.updateFields(newFormData);
      } else {
        const newFormdata = resetOptionFields(this.state.formdata, 'recipient_name');
        newFormdata.recipient_name.loadstatus = false;
        this.updateFields(newFormdata);
      }
    }
    /* if (!actionloading && getloadtype === 'carrier') {
      setloadtype('');
      if (Object.keys(carrierdata).length !== 0) {
        const carrier = [];
        Object.keys(carrierdata).forEach((key) => {
          carrier.push({ _id: key, name: key });
        });

        const newFormData = populateOptionFields(this.state.formdata, carrier, 'carrier_code');
        this.updateFields(newFormData);
      }
    } */
  }

  onRecipientChange(recipientvalue) {
    clearTimeout(this.timer);
    this.setState({ ...this.state, recipientvalue });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    const newFormdata = { ...this.state.formdata };
    newFormdata.recipient_name.loadstatus = true;
    this.updateFields(newFormdata);
  }

  triggerChange = () => {
    const { recipientvalue } = this.state;
    const {
      getAutoRecipient, resetAutoRecipient, clientId, setloadtype,
    } = this.props;
    const cid = clientId === 0 ? null : clientId;
    if (recipientvalue.trim() !== '') {
      getAutoRecipient(cid, recipientvalue);
    } else {
      resetAutoRecipient();
    }
    setloadtype('auto vendor');
  }
  addNewClicked = (addNew, value) => {
    const { autoRecipient/* , handleClose */ } = this.props;
    let vendorInfo = [];
    let selectedVendor = {};
    if (autoRecipient.length) {
      vendorInfo = autoRecipient
        .filter(data => data.attributes.id === value)
        .map(vendor => (vendor.attributes));
      const [vendorData] = vendorInfo;
      selectedVendor = vendorData;
    }
    if (typeof selectedVendor === 'undefined') {
      selectedVendor = {};
    }
    const newFormdata = setFieldValue(this.state.formdata, 'recipient_name', value);
    this.updateFields(newFormdata);
    /* let rname = '';
    for (const k in newFormdata.recipient_name.config.options) { // eslint-disable-line
      if (newFormdata.recipient_name.config.options[k].key === newFormdata.recipient_name.value) {
        rname = newFormdata.recipient_name.config.options[k].value;
      }
    }
    const filterData = { id: 1, recipient_name: rname };
    this.addSearchFilter(filterData, 'recipient_name');
    handleClose(); */
  }
  addSearchFilter = (filterData, elid) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          if (elid === 'recipient_name') {
            filterItems[key].recipient_name = filterData.recipient_name;
          }
          /* if (elid === 'carrier_code') {
            filterItems[key].carrier_code = filterData.carrier_code;
          } */
          if (elid === 'company_name') {
            filterItems[key].company_name = filterData.company_name;
          }
          if (elid === 'recipient_email') {
            filterItems[key].recipient_email = filterData.recipient_email;
          }
          if (elid === 'all') {
            filterItems[key].recipient_name = filterData.recipient_name;
            filterItems[key].company_name = filterData.company_name;
            filterItems[key].recipient_email = filterData.recipient_email;
          }
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  updateForm = (element) => {
    const newFormdata = update(element, this.state.formdata, '');
    this.setState({
      formdata: newFormdata,
    });
  }

  handleCompanySubmit = () => {
    const { formdata } = this.state;
    const { handleClose } = this.props;
    if (formdata.company_name.value !== '') {
      const filterData = { id: 6, company_name: formdata.company_name.value };
      this.addSearchFilter(filterData, 'company_name');
      handleClose();
    }
  }

  handleEmailSubmit = () => {
    const { formdata } = this.state;
    const { handleClose } = this.props;
    if (formdata.recipient_email.value !== '') {
      const filterData = { id: 7, recipient_email: formdata.recipient_email.value };
      this.addSearchFilter(filterData, 'recipient_email');
      handleClose();
    }
  }

  handleSubmit = () => {
    const { formdata } = this.state;
    const { handleClose } = this.props;
    let rname = '';
    for (const k in formdata.recipient_name.config.options) { // eslint-disable-line
      if (formdata.recipient_name.config.options[k].key === formdata.recipient_name.value) {
        rname = formdata.recipient_name.config.options[k].value;
      }
    }
    const filterData = {
      id: 8,
      recipient_name: rname,
      recipient_value: formdata.recipient_name.value,
      company_name: formdata.company_name.value,
      recipient_email: formdata.recipient_email.value,
    };
    this.addSearchFilter(filterData, 'all');
    handleClose();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <List className={classes.ulistcontainer}>
          <ListItem>
            <FormField
              id="recipient_name"
              formdata={this.state.formdata.recipient_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="company_name"
              formdata={this.state.formdata.company_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="recipient_email"
              formdata={this.state.formdata.recipient_email}
              change={element => this.updateForm(element)}
            />
          </ListItem>
          {/* <ListItem>
            <FormField
              id="carrier_code"
              formdata={this.state.formdata.carrier_code}
              change={element => this.updateForm(element)}
            />
          </ListItem> */}
        </List>
        <div className={classes.btnwrapper}>
          <Button
            color="secondary"
            variant="contained"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Fragment>
    );
  }
}

MoreOrderFilterForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  autoRecipient: PropTypes.instanceOf(Object).isRequired,
  getAutoRecipient: PropTypes.func.isRequired,
  resetAutoRecipient: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  clientId: PropTypes.number.isRequired,
  /* getCarrier: PropTypes.func.isRequired, */
  handleClose: PropTypes.func.isRequired,
  /* carrierdata: PropTypes.instanceOf(Object).isRequired, */
};

export default withStyles(styles)(MoreOrderFilterForm);
