import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'ui/components/form/FormField';
import { update, setFieldValue, populateOptionFields, resetOptionFields, populateFields } from 'ui/components/form/FormActions';
import styles from './OrderFilterFormStyle';

const WAIT_INTERVAL = 500;

class OrderFilterForm extends Component {
  state = {
    formdata: {
      recipientvalue: '',
      order_date_start: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_start',
          type: 'date',
          label: 'From',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      order_date_end: {
        element: 'date',
        value: null,
        config: {
          name: 'order_date_end',
          type: 'date',
          label: 'To',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      recipient_name: {
        element: 'autocomplete',
        value: '',
        config: {
          name: 'recipient_name',
          type: 'number',
          label: 'Recipient Name',
          options: [],
          editing: false,
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
        addNewClicked: (addNew, value) => this.addNewClicked(addNew, value),
        onChange: value => this.onRecipientChange(value),
        hasfunction: true,
        loadstatus: false,
        loadbtnstatus: false,
      },
      status: {
        element: 'select',
        value: '',
        config: {
          label: 'Status',
          name: 'status',
          options: [
            { key: 0, value: 'New' },
            { key: 1, value: 'Processing' },
            { key: 2, value: 'On Hold' },
            { key: 3, value: 'Cancelled' },
            { key: 5, value: 'Backordered' },
            { key: 6, value: 'Hold For Client Instructions' },
            { key: 7, value: 'Hold For Instructions' },
            { key: 8, value: 'Hold For Labels' },
            { key: 9, value: 'Hold Potential Fraud' },
            { key: 10, value: 'Invalid Address' },
          ],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
      },
      carrier_code: {
        element: 'select',
        value: '',
        config: {
          label: 'Carrier Code',
          name: 'carrier_code',
          options: [],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        showlabel: true,
        addeditform: true,
        showEmpty: true,
      },
      company_name: {
        element: 'input',
        value: '',
        config: {
          label: 'Company Name',
          name: 'company_name',
          type: 'text',
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
        addeditform: true,
      },
      recipient_email: {
        element: 'input',
        value: '',
        config: {
          name: 'recipient_email',
          type: 'email',
          label: 'Email',
        },
        validation: {
          required: false,
          email: false,
        },
        valid: true,
        touched: true,
        validationMessage: '',
      },
    },
  }

  componentWillMount() {
    /* const {
      getCarrier, carrierdata, setloadtype,
    } = this.props;
    if (!carrierdata.length) {
      setloadtype('carrier');
      getCarrier();
    } */
    const { filtersdata } = this.props;
    const data = filtersdata.filter(sel => sel.id === 8);
    if (data.length) {
      const list = [{ _id: data[0].recipient_value, name: data[0].recipient_name }];
      const newFormdata = populateOptionFields(this.state.formdata, list, 'recipient_name');
      newFormdata.recipient_name.loadstatus = false;
      newFormdata.recipient_name.value = data[0].recipient_value;
      newFormdata.recipient_name.valid = true;
      newFormdata.recipient_name.touched = true;
      newFormdata.recipient_name.config.editing = true;

      newFormdata.company_name.value = data[0].company_name;
      newFormdata.recipient_email.value = data[0].recipient_email;
      this.updateFields(newFormdata);
    }
    this.timer = null;
  }

  componentDidMount() {
    const { filtersdata } = this.props;
    if (filtersdata.length) {
      for (const key in filtersdata) { // eslint-disable-line
        if (typeof filtersdata[key].order_date_start !== 'undefined') {
          const data = {
            order_date_start: filtersdata[key].order_date_start,
          };
          populateFields(this.state.formdata, data);
        }
        if (typeof filtersdata[key].order_date_end !== 'undefined') {
          const data = {
            order_date_end: filtersdata[key].order_date_end,
          };
          populateFields(this.state.formdata, data);
        }
      }
    }
  }

  componentDidUpdate() {
    const {
      getloadtype, setloadtype, actionloading, autoRecipient, /* carrierdata, */
    } = this.props;
    if (!actionloading && getloadtype === 'auto vendor') {
      setloadtype('');
      if (autoRecipient.length) {
        const list = [];
        for (const key in autoRecipient) { // eslint-disable-line
          list.push({ _id: autoRecipient[key].attributes.id, name: autoRecipient[key].attributes.recipient_name }); // eslint-disable-line
        }
        const newFormData = populateOptionFields(this.state.formdata, list, 'recipient_name');
        newFormData.recipient_name.loadstatus = false;
        this.updateFields(newFormData);
      } else {
        const newFormdata = resetOptionFields(this.state.formdata, 'recipient_name');
        newFormdata.recipient_name.loadstatus = false;
        this.updateFields(newFormdata);
      }
    }
    /* if (!actionloading && getloadtype === 'carrier') {
      setloadtype('');
      if (Object.keys(carrierdata).length !== 0) {
        const carrier = [];
        Object.keys(carrierdata).forEach((key) => {
          carrier.push({ _id: key, name: key });
        });

        const newFormData = populateOptionFields(this.state.formdata, carrier, 'carrier_code');
        this.updateFields(newFormData);
      }
    } */
  }
  /* onRecipientChange = (txt) => {
    const { getAutoRecipient, resetAutoRecipient, setloadtype } = this.props;
    if (txt.trim() !== '') {
      getAutoRecipient(txt);
    } else {
      resetAutoRecipient();
    }
    setloadtype('auto vendor');
    const newFormdata = { ...this.state.formdata };
    newFormdata.recipient_name.loadstatus = true;
    this.updateFields(newFormdata);
  } */

  onRecipientChange(recipientvalue) {
    clearTimeout(this.timer);
    this.setState({ ...this.state, recipientvalue });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    const newFormdata = { ...this.state.formdata };
    newFormdata.recipient_name.loadstatus = true;
    this.updateFields(newFormdata);
  }

  triggerChange = () => {
    const { recipientvalue } = this.state;
    const { getAutoRecipient, resetAutoRecipient, setloadtype } = this.props;
    if (recipientvalue.trim() !== '') {
      getAutoRecipient(recipientvalue);
    } else {
      resetAutoRecipient();
    }
    setloadtype('auto vendor');
  }

  /* getFormattedDate = (plus = 0) => {
    const date = new Date();
    if (plus > 0) {
      date.setDate(date.getDate() + plus);
    }
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  } */
  addNewClicked = (addNew, value) => {
    const { autoRecipient/* , handleClose */ } = this.props;
    let vendorInfo = [];
    let selectedVendor = {};
    if (autoRecipient.length) {
      vendorInfo = autoRecipient
        .filter(data => data.attributes.id === value)
        .map(vendor => (vendor.attributes));
      const [vendorData] = vendorInfo;
      selectedVendor = vendorData;
    }
    if (typeof selectedVendor === 'undefined') {
      selectedVendor = {};
    }
    const newFormdata = setFieldValue(this.state.formdata, 'recipient_name', value);
    this.updateFields(newFormdata);
    /* let rname = '';
    for (const k in newFormdata.recipient_name.config.options) { // eslint-disable-line
      if (newFormdata.recipient_name.config.options[k].key === newFormdata.recipient_name.value) {
        rname = newFormdata.recipient_name.config.options[k].value;
      }
    }
    const filterData = { id: 1, recipient_name: rname };
    this.addSearchFilter(filterData, 'recipient_name');
    handleClose(); */
  }
  addSearchFilter = (filterData, elid) => {
    const { filtersdata, setfilters, setloadtype } = this.props;
    const filterItems = [...filtersdata];
    if (filterItems.length) {
      let notfound = true;
      for (const key in filterItems) { // eslint-disable-line
        if (filterItems[key].id === filterData.id) {
          if (elid === 'recipient_name') {
            filterItems[key].recipient_name = filterData.recipient_name;
          }
          if (elid === 'order_date_start') {
            filterItems[key].order_date_start = filterData.order_date_start;
          }
          if (elid === 'order_date_end') {
            filterItems[key].order_date_end = filterData.order_date_end;
          }
          if (elid === 'status') {
            filterItems[key].status = filterData.status;
            filterItems[key].type = filterData.type;
          }
          /* if (elid === 'carrier_code') {
            filterItems[key].carrier_code = filterData.carrier_code;
          } */
          if (elid === 'all') {
            filterItems[key].recipient_name = filterData.recipient_name;
            filterItems[key].company_name = filterData.company_name;
            filterItems[key].recipient_email = filterData.recipient_email;
          }
          notfound = false;
        }
      }
      if (notfound) {
        filterItems.push({ ...filterData });
      }
    } else {
      filterItems.push({ ...filterData });
    }
    setfilters(filterItems);
    setloadtype('filter updated');
  }
  updateFields = (newFormdata) => {
    this.setState({
      ...this.state,
      formdata: newFormdata,
    });
  }
  updateForm = (element) => {
    const { handleClose } = this.props;
    let closenow = false;
    const newFormdata = update(element, this.state.formdata, '');
    if (element.id === 'order_date_start') {
      const filterData = { id: 2, order_date_start: newFormdata.order_date_start.value };
      this.addSearchFilter(filterData, 'order_date_start');
      if (newFormdata.order_date_end.value !== null) {
        closenow = true;
      }
    }
    if (element.id === 'order_date_end') {
      const filterData = { id: 3, order_date_end: newFormdata.order_date_end.value };
      this.addSearchFilter(filterData, 'order_date_end');
      if (newFormdata.order_date_start.value !== null) {
        closenow = true;
      }
    }
    if (element.id === 'status') {
      const status = newFormdata.status.config.options
        .filter(data => data.key === parseInt(newFormdata.status.value, 10))
        .map(type => (type.value));
      const filterData = {
        id: 4,
        type: parseInt(newFormdata.status.value, 10),
        status: status[0],
      };
      this.addSearchFilter(filterData, 'status');
      closenow = true;
    }
    /* if (element.id === 'carrier_code') {
      const filterData = { id: 5, carrier_code: newFormdata.carrier_code.value };
      this.addSearchFilter(filterData, 'carrier_code');
      closenow = true;
    } */
    this.setState({
      formdata: newFormdata,
    });
    if (closenow) {
      handleClose();
    }
  }

  handleSubmit = () => {
    const { formdata } = this.state;
    const { handleClose } = this.props;
    let rname = '';
    for (const k in formdata.recipient_name.config.options) { // eslint-disable-line
      if (formdata.recipient_name.config.options[k].key === formdata.recipient_name.value) {
        rname = formdata.recipient_name.config.options[k].value;
      }
    }
    const filterData = {
      id: 8,
      recipient_name: rname,
      recipient_value: formdata.recipient_name.value,
      company_name: formdata.company_name.value,
      recipient_email: formdata.recipient_email.value,
    };
    this.addSearchFilter(filterData, 'all');
    handleClose();
  }

  render() {
    const { classes, location } = this.props;
    let displayStatus = true;
    switch (location.pathname) {
      case '/orders':
        break;
      case '/pending-orders':
      case '/shipped-orders':
      case '/returned-orders':
        displayStatus = false;
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <List className={classes.ulistcontainer}>
          <ListItem className="datelist">
            <Typography variant="h6" className={classes.h6}>
              Order Date
            </Typography>
            <Grid container spacing={24}>
              <Grid className="datestart" item xs={6}>
                <FormField
                  id="order_date_start"
                  formdata={this.state.formdata.order_date_start}
                  change={element => this.updateForm(element)}
                />
              </Grid>
              <Grid className="dateend" item xs={6}>
                <FormField
                  id="order_date_end"
                  formdata={this.state.formdata.order_date_end}
                  change={element => this.updateForm(element)}
                />
              </Grid>
            </Grid>
          </ListItem>
          { displayStatus ?
            <ListItem>
              <FormField
                id="status"
                formdata={this.state.formdata.status}
                change={element => this.updateForm(element)}
              />
            </ListItem>
          : null }
          {/* <ListItem>
            <FormField
              id="carrier_code"
              formdata={this.state.formdata.carrier_code}
              change={element => this.updateForm(element)}
            />
          </ListItem> */}
          <ListItem className="gr1">
            <FormField
              id="recipient_name"
              formdata={this.state.formdata.recipient_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="company_name"
              formdata={this.state.formdata.company_name}
              change={element => this.updateForm(element)}
            />
            <p className="space" />
            <FormField
              id="recipient_email"
              formdata={this.state.formdata.recipient_email}
              change={element => this.updateForm(element)}
            />
          </ListItem>
          <div className={classes.btnwrapper}>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </List>
      </Fragment>
    );
  }
}

OrderFilterForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  autoRecipient: PropTypes.instanceOf(Object).isRequired,
  getAutoRecipient: PropTypes.func.isRequired,
  resetAutoRecipient: PropTypes.func.isRequired,
  setloadtype: PropTypes.func.isRequired,
  getloadtype: PropTypes.string.isRequired,
  actionloading: PropTypes.bool.isRequired,
  setfilters: PropTypes.func.isRequired,
  filtersdata: PropTypes.instanceOf(Object).isRequired,
  /* getCarrier: PropTypes.func.isRequired, */
  handleClose: PropTypes.func.isRequired,
  /* carrierdata: PropTypes.instanceOf(Object).isRequired, */
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OrderFilterForm);
